/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { chain, get, isUndefined, clone, flatMap } from 'lodash';
import {
  sub,
  startOfDay,
  endOfDay,
  formatISO,
  compareDesc,
  parseISO,
} from 'date-fns';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { createQuery } from '@fivehealth/botero';
import { buildPaginatedResponse } from 'lib/pagination';
import useMonitoringFormsets from './useMonitoringFormsets';

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmissions(
    $orderField: SubmissionOrderBy
    $orderDesc: Boolean
    $first: Int
    $offset: Int
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
    $patientIds: [String]
    $clinicianIcUid: [String!]
    $isAlertsTriggered: Boolean
    $isAlertsResolved: Boolean
    $patientFormUids: [String]
    $monitoringFormUidIn: [String]
    $patientName: String
    $hasUnseenCommentsAndVisibleToEverybody: Boolean
    $hasUnseenComments: Boolean
    $hasCommentVisibleTo: SubmissionCommentVisibleToEnum
  ) {
    cleoSubmissions(
      sortBy: $orderField
      sortDesc: $orderDesc
      first: $first
      offset: $offset
      beforeDateTime: $beforeDateTime
      afterDateTime: $afterDateTime
      patientForm_Uid_In: $patientFormUids
      patientForm_Patient_Uid_In: $patientIds
      isAlertsTriggered: $isAlertsTriggered
      isAlertsResolved: $isAlertsResolved
      patientForm_MonitoringForm_Uid_In: $monitoringFormUidIn
      patientSearch: $patientName
      icUidIn: $clinicianIcUid
      alerteeUidIn: $clinicianIcUid
      hasUnseenComments: $hasUnseenComments
      hasCommentVisibleTo: $hasCommentVisibleTo
      hasUnseenCommentsAndVisibleToEverybody: $hasUnseenCommentsAndVisibleToEverybody
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          uid
          submittedOn
          patient {
            uid
            name
            phone
            communicationMethod
            ics {
              uid
              name
            }
            alertees {
              uid
              name
            }
            deactivatedOn
          }
          patientForm {
            uid
            ics {
              uid
              name
            }
            alertees {
              uid
              name
            }
            monitoringForm {
              effectiveName
              monitoringFormset {
                uid
              }
            }
            isDischarged
          }

          isAlertsTriggered
          isAlertsResolved

          triggeredAlerts {
            uid
            rule {
              uid
              name
            }
            alertReasons {
              reason
              submissionValue {
                uid
                value
              }
            }
            createdOn
            resolvingComment {
              uid
              clinician {
                uid
                name
              }
              content
              createdOn
            }
          }

          lastSeenByPatientOn
          lastSeenOn
          lastSeens {
            uid
            clinician {
              uid
              name
            }
            seenOn
            id
          }
          comments {
            uid
            clinician {
              name
              designation
            }
            content
            createdOn
            isSeen
            seenByClinicians {
              uid
            }
            taggedClinicians {
              uid
              name
              isCaregiver
            }
            visibleTo
          }
          values {
            uid
            parameter {
              uid
              groupName
              unit
              name
              valueType
              sortOrder
              unit
              numberPrecision
            }
            value
            valueType
            extracted
            extractedForDisplay
          }
          variables
          variablesAll
        }
      }
    }
  }
`;

export const alerts = [
  {
    id: 'unresolved',
    label: 'Alert (Unresolved)',
    icon: <StatusIcon status="warning" />,
    toParams: {
      isAlertsResolved: false,
    },
  },
  {
    id: 'resolved',
    label: 'Alert (Resolved)',
    icon: <StatusIcon status="danger" />,
    toParams: {
      isAlertsResolved: true,
    },
  },
  {
    id: 'normal',
    label: 'Normal',
    icon: <StatusIcon status="success" />,
    toParams: {
      isAlertsTriggered: false,
    },
  },
];

export const lastReports = [
  ...[24, 48, 72].map((num) => ({
    id: `last_${num}_hours`,
    label: `Last ${num} hours`,
    toParams: {
      noReportHours: num,
    },
  })),
  {
    id: 'last_7_days',
    label: 'Last 7 days',
    toParams: {
      noReportHours: 24 * 7,
    },
  },
];

export const dateRange = [
  {
    id: 'today',
    label: 'Today',
    toParams: {
      afterDateTime: formatISO(endOfDay(sub(new Date(), { days: 1 }))),
    },
  },
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 6 }))),
    },
  },
  {
    id: 'last_14_days',
    label: 'Last 14 days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 13 }))),
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 29 }))),
    },
  },
];
// NOTE: Keep this for now
// export const commentTypes = [
//   {
//     id: 'ICS_AND_ALERTEES',
//     label: 'Private',
//     key: 'icsAndAlertees',
//     toParams: { visibileTo: 'ICS_AND_ALERTEES' },
//   },
//   {
//     id: 'EVERYBODY',
//     label: 'Public',
//     key: 'everybody',
//     toParams: { visibileTo: 'EVERYBODY' },
//   },
// ];

export const mapSubmission = (submissionData) => {
  let status = 'stable';
  const alert = get(submissionData, 'triggeredAlerts');
  if (alert.length > 0) {
    status = flatMap(alert.map((a) => a.resolvingComment)).find((c) => c)
      ? 'danger'
      : 'warning';
  }

  const values = chain(submissionData)
    .get('values', [])
    .sortBy(({ parameter: { sortOrder } }) => sortOrder)
    .value();

  const groupedParameters = values.reduce((acc, param) => {
    const { groupName } = param.parameter || {};
    if (groupName && groupName.length > 0) {
      acc[groupName] = [...(acc[groupName] || []), param];
    }
    return acc;
  }, {});

  const cGroupParameters = clone(groupedParameters);

  const submissionParameters = values
    .map((value) => {
      const {
        extractedForDisplay: parameterValue,
        parameter: { groupName, name } = {},
        valueType,
      } = value || {};

      if (groupedParameters[groupName]) {
        const groupValues = groupedParameters[groupName]
          .map(({ extractedForDisplay }) => extractedForDisplay)
          .join('/');
        delete groupedParameters[groupName];
        if (!groupName) {
          return '';
        }
        return `${groupName}: ${groupValues || 'N/A'}`;
      }
      if (groupName) {
        return null;
      }
      if (name && valueType === 'FILE' && parameterValue) {
        return `[${name}](${parameterValue})`;
      }
      if (name) {
        return `${name}: ${parameterValue || 'N/A'}`;
      }
      return '';
    })
    .filter(Boolean);

  const submissionParametersList = values
    .map((value) => {
      const {
        extractedForDisplay: parameterValue,
        parameter: { groupName, name } = {},
        valueType,
      } = value || {};

      const isTriggeredAlert = flatMap(alert.map((a) => a.alertReasons)).find(
        (a) => a.submissionValue?.uid === value.uid
      );

      if (cGroupParameters[groupName]) {
        const groupValues = cGroupParameters[groupName]
          .map(({ extractedForDisplay }) => extractedForDisplay)
          .join('/');
        return {
          ...value.parameter,
          extractedForDisplay: `${groupValues || 'N/A'}`,
          isTriggeredAlert,
        };
      }

      if (name && valueType === 'FILE' && parameterValue) {
        return {
          ...value.parameter,
          extractedForDisplay: `[${name}](${parameterValue})`,
          isTriggeredAlert,
        };
      }

      if (name) {
        return {
          ...value.parameter,
          extractedForDisplay: `${parameterValue || 'N/A'}`,
          isTriggeredAlert,
        };
      }

      return null;
    })
    .filter(Boolean);

  let statusColor = status;
  if (status === 'stable') {
    statusColor = 'success';
  } else if (status === 'warning') {
    statusColor = 'danger';
  }

  const filteredGroupedParams = submissionParametersList
    .reduce((prev, curr) => {
      const index = prev.findIndex(
        (o) => !!o.groupName && o.groupName === curr.groupName
      );

      if (index < 0) {
        const groupDetails = values
          .filter(
            (f) =>
              !!f.parameter.groupName &&
              f.parameter.groupName === curr.groupName
          )
          .map((o) => ({
            submissionValueUID: o.uid,
            ...o.parameter,
            extractedForDisplay: o.extractedForDisplay,
            value: o.value,
          }));
        const result = {
          ...curr,
          name: `${curr.groupName || curr.name}`,
          groupDetails,
        };

        if (groupDetails && groupDetails.length === 0) {
          delete result.groupDetails;
        }
        prev.push(result);
      }
      return prev;
    }, [])
    .map((m) => {
      let { isTriggeredAlert } = m;
      if (m?.groupDetails && m?.groupDetails.length > 0) {
        isTriggeredAlert =
          m.groupDetails.filter((o) =>
            flatMap(alert.map((a) => a.alertReasons)).find(
              (f) => f?.submissionValue?.uid === o.submissionValueUID
            )
          ).length > 0;
      }

      return {
        ...m,
        isTriggeredAlert,
      };
    });

  return {
    ...submissionData,
    status,
    statusColor,
    submissionParameters: submissionParameters.join(', '),
    parameters: submissionParameters,
    submissionGroupedParams: filteredGroupedParams,
  };
};

const useSubmissions = () => (args) => {
  // TODO: React Hook "useMonitoringFormsets" cannot be called inside a callback.
  /* eslint-disable react-hooks/rules-of-hooks */
  const { data: monitoringFormsets } = useMonitoringFormsets({
    variables: {
      isEnrollmentForm: false,
    },
  });
  const { paginate } = args;
  const submissions = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'submissions',
    paginate: isUndefined(paginate) ? 'beforeDateTime' : paginate,
    filters: {
      alerts: { id: 'alerts', title: 'Alerts', data: alerts },
      lastReport: {
        id: 'lastReport',
        title: 'No reports',
        data: lastReports,
      },
      dateRange: { id: 'dateRange', title: 'Date range', data: dateRange },
      monitoringFormsets: {
        id: 'monitoringFormsets',
        title: 'Monitoring forms',
        data: monitoringFormsets,
        multiSelect: true,
      },
    },
    getNextPageParam: ({ cleoSubmissions: data } = {}, allPages) => {
      if (paginate === 'offset') {
        const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
        if (!hasNextPage) {
          return undefined;
        }

        const currentOffset = allPages.flatMap(({ cleoSubmissions }) =>
          get(cleoSubmissions, 'edges', [])
        ).length;
        return currentOffset;
      }

      const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
      if (!hasNextPage) {
        return undefined;
      }
      const edges = get(data, 'edges', [])
        .map(({ node }) => node)
        .filter(Boolean);

      const sortedData = edges.sort((a, b) =>
        compareDesc(parseISO(a.submittedOn), parseISO(b.submittedOn))
      );
      return get(sortedData[sortedData.length - 1], 'submittedOn');
    },
    select: (data) =>
      buildPaginatedResponse(data, 'cleoSubmissions', mapSubmission),
    keepPreviousData: true,
  });
  return submissions(args);
};

// TODO: React Hook "useSubmissions" cannot be called at the top level.
/* eslint-disable react-hooks/rules-of-hooks */
export default useSubmissions();
