const en = {
  'Only 3 months from the previously selected date is allowed':
    'Only 3 months from the previously selected date is allowed',
  of: 'of',
  Showing: 'Showing',
  'Clear All': 'Clear All',
  Advanced: 'Advanced',
  Gender: 'Gender',
  'Messaging platform': 'Messaging platform',
  'Last 60 days': 'Last 60 days',
  'Last 90 days': 'Last 90 days',
  'Error downloading file, please try again.':
    'Error downloading file, please try again.',
  'Download successful': 'Download successful',
  'Your download was successful': 'Your download was successful',
  'Please wait for the current upload to finish and try again':
    'Please wait for the current upload to finish and try again',
  Programs: 'Programs',
  'Gender/DOB': 'Gender/DOB',
  results: 'results',
  'Created by': 'Created by',
  'Active patients': 'Active patients',
  Description: 'Description',
  'Enrolment Date': 'Enrolment Date',
  'Removed Programs': 'Removed Programs',
  'Patient Name/ID': 'Patient Name/ID',
  'Enter group name': 'Enter group name',
  'Group description': 'Group description',
  'Group name': 'Group name',
  'Search by group name & description': 'Search by group name & description',
  'Create patient groups to perform bulk actions (e.g. send broadcast message)':
    'Create patient groups to perform bulk actions (e.g. send broadcast message)',
  'Send message to patient': 'Send message to patient',
  'Message Logs': 'Message Logs',
  'Add program': 'Add program',
  'Created on': 'Created on',
  'Add Program': 'Add Program',
  'Select the program you want to remove them from':
    'Select the program you want to remove them from',
  Email: 'Email',
  'Your session is expired, log out and log back in again':
    'Your session is expired, log out and log back in again',
  'patients assigned to the user will need to be reassigned.':
    'patients assigned to the user will need to be reassigned.',
  'Try adjusting your search or filter to find what you’re looking for.':
    'Try adjusting your search or filter to find what you’re looking for.',
  About: 'About',
  'Activate/pause reminders': 'Activate/pause reminders',
  Active: 'Active',
  'Active forms': 'Active forms',
  'Active Patients': 'Active Patients',
  'Add a comment': 'Add a comment',
  'Add another family member': 'Add another family member',
  'Add another user': 'Add another user',
  'Add Family Member': 'Add Family Member',
  'Add Family Member(s)': 'Add Family Member(s)',
  'Add New Clinical User': 'Add New Clinical User',
  'Add New Family Member': 'Add New Family Member',
  'Add new patient': 'Add new patient',
  'Add Patient': 'Add Patient',
  'Add new user': 'Add new user',
  'Add clinical user': 'Add clinical user',
  'Add reminder': 'Add reminder',
  'Add users': 'Add users',
  'Advanced reminder settings': 'Advanced reminder settings',
  'After a fixed number of reminders have been sent':
    'After a fixed number of reminders have been sent',
  'After patient discharge': 'After patient discharge',
  'After patient enrollment': 'After patient enrollment',
  Alert: 'Alert',
  'Alert Threshold': 'Alert Threshold',
  'Alert thresholds': 'Alert thresholds',
  'Alerted via': 'Alerted via',
  All: 'All',
  'All patient data': 'All patient data',
  'All Patients': 'All Patients',
  'All submission data': 'All submission data',
  'All-time': 'All-time',
  "Apply a filter for the Clinical User's name to view all patients they are assigned to.":
    "Apply a filter for the Clinical User's name to view all patients they are assigned to.",
  'Apply filters': 'Apply filters',
  Apr: 'Apr',
  April: 'April',
  'Are you sure you want to leave Create/Edit Reminders page without saving your changes?':
    'Are you sure you want to leave Create/Edit Reminders page without saving your changes?',
  'At what time should your patient(s) receive reminders':
    'At what time should your patient(s) receive reminders',
  Aug: 'Aug',
  August: 'August',
  Back: 'Back',
  'Back to Clinical Settings': 'Back to Clinical Settings',
  Cancel: 'Cancel',
  'Change language': 'Change language',
  'Change profile picture': 'Change profile picture',
  'Change workspace': 'Change workspace',
  'Choose your dashboard language': 'Choose your dashboard language',
  'Clinical Admin': 'Clinical Admin',
  'Clinical Alerts': 'Clinical Alerts',
  'Clinical Designation': 'Clinical Designation',
  'Clinical designation': 'Clinical designation',
  'Clinical Settings': 'Clinical Settings',
  'Clinical user': 'Clinical user',
  'Clinical user(s) in-charge': 'Clinical user(s) in-charge',
  'Clinical user(s) to alert': 'Clinical user(s) to alert',
  'Clinical users': 'Clinical users',
  'Clinical Users': 'Clinical Users',
  Close: 'Close',
  Comments: 'Comments',
  Contact: 'Contact',
  'Contact Us': 'Contact Us',
  'Continue to receive alerts for all Clinical User(s)':
    'Continue to receive alerts for all Clinical User(s)',
  'Create reminder': 'Create reminder',
  Current: 'Current',
  'Current day': 'Current day',
  'Current month': 'Current month',
  'Current reminder': 'Current reminder',
  'Current week': 'Current week',
  'Custom Range': 'Custom Range',
  CUSTOMIZE: 'CUSTOMIZE',
  'Customize for this individual': 'Customize for this individual',
  'Customize one-time reminder': 'Customize one-time reminder',
  'Customize reminder frequency': 'Customize reminder frequency',
  'Data labels': 'Data labels',
  Date: 'Date',
  'Date range': 'Date range',
  Day: 'Day',
  'day ago': 'day ago',
  'Day(s)': 'Day(s)',
  'Day(s) after reminder': 'Day(s) after reminder',
  'Day(s) after trigger event': 'Day(s) after trigger event',
  'Day(s) before reminder': 'Day(s) before reminder',
  'days ago': 'days ago',
  'Deactivate {{profile name}}?': 'Deactivate {{profile name}}?',
  'Deactivate profile': 'Deactivate profile',
  'Deactivate user': 'Deactivate user',
  Deactivated: 'Deactivated',
  Dec: 'Dec',
  December: 'December',
  Default: 'Default',
  'Define export': 'Define export',
  'Export your patient data': 'Export your patient data',
  'Delete reminder': 'Delete reminder',
  Discard: 'Discard',
  'Discharge {{profile name}}?': 'Discharge {{profile name}}?',
  'Discharge Patient': 'Discharge Patient',
  'Discharge patient': 'Discharge patient',
  Discharged: 'Discharged',
  'Discharged Date': 'Discharged Date',
  'Discharged forms': 'Discharged forms',
  'Download {{profile name}} submissions':
    'Download {{profile name}} submissions',
  'Download data': 'Download data',
  'Download file': 'Download file',
  'Download submissions': 'Download submissions',
  Duration: 'Duration',
  "Duration before and after a reminder in which a patient's submission is considered to be compliant.":
    "Duration before and after a reminder in which a patient's submission is considered to be compliant.",
  Edit: 'Edit',
  'Edit alert threshold': 'Edit alert threshold',
  'Edit Clinical User': 'Edit Clinical User',
  'Edit clinical users': 'Edit clinical users',
  'Edit duration': 'Edit duration',
  'Edit Form Information': 'Edit Form Information',
  'Edit forms, clinical users, reminders, caregivers':
    'Edit forms, clinical users, reminders, caregivers',
  'Edit Patient Details': 'Edit Patient Details',
  'Edit patient details': 'Edit patient details',
  'Edit profile': 'Edit profile',
  'Edit time & frequency': 'Edit time & frequency',
  'Enroll {{profile name}}?': 'Enroll {{profile name}}?',
  'Enroll Patient': 'Enroll Patient',
  'Enrolled Date': 'Enrolled Date',
  'Enrollment Date': 'Enrollment Date',
  'Enter clinical designation': 'Enter clinical designation',
  Equals: 'Equals',
  Export: 'Export',
  'Export as CSV': 'Export as CSV',
  'Export as PDF': 'Export as PDF',
  'Export data': 'Export data',
  'Select file format': 'Select file format',
  'Export destination': 'Export destination',
  'Download method': 'Download method',
  'Export graphs': 'Export graphs',
  'Export patient list': 'Export patient list',
  'Export table': 'Export table',
  'Export user list': 'Export user list',
  'Download user list': 'Download user list',
  'Family Member': 'Family Member',
  'Family Member Information': 'Family Member Information',
  'Family Member(s)': 'Family Member(s)',
  'Family Members': 'Family Members',
  Feb: 'Feb',
  February: 'February',
  Filter: 'Filter',
  'Filter by': 'Filter by',
  Filters: 'Filters',
  'First Name': 'First Name',
  'First name': 'First name',
  "Follow-up reminders will be automatically sent to patients if they don't respond to the original reminder. They can be sent up to 8 hours after the initial reminder.":
    "Follow-up reminders will be automatically sent to patients if they don't respond to the original reminder. They can be sent up to 8 hours after the initial reminder.",
  'Form Information': 'Form Information',
  Forms: 'Forms',
  Frequency: 'Frequency',
  'GLOBAL DEFAULTS': 'GLOBAL DEFAULTS',
  'Grand average': 'Grand average',
  Graph: 'Graph',
  Graphs: 'Graphs',
  'Greater than': 'Greater than',
  'Greater than or equal': 'Greater than or equal',
  'Help Guide': 'Help Guide',
  here: 'here',
  'Hide advanced reminder settings': 'Hide advanced reminder settings',
  History: 'History',
  hour: 'hour',
  'hour ago': 'hour ago',
  'Hour(s) after reminder': 'Hour(s) after reminder',
  'Hour(s) after trigger event': 'Hour(s) after trigger event',
  'Hour(s) before reminder': 'Hour(s) before reminder',
  'hours ago': 'hours ago',
  'Immediately upon enrollment': 'Immediately upon enrollment',
  'Input duration': 'Input duration',
  Jan: 'Jan',
  January: 'January',
  Jul: 'Jul',
  July: 'July',
  Jun: 'Jun',
  June: 'June',
  'Last 1 day': 'Last 1 day',
  'Last 12 hours': 'Last 12 hours',
  'Last 14 Days': 'Last 14 Days',
  'Last 2 hours': 'Last 2 hours',
  'Last 24 hours': 'Last 24 hours',
  'Last 30 days': 'Last 30 days',
  'Last 4 hours': 'Last 4 hours',
  'Last 48 hours': 'Last 48 hours',
  'Last 7 Days': 'Last 7 Days',
  'Last 7 days': 'Last 7 days',
  'Last 72 hours': 'Last 72 hours',
  'Last Name': 'Last Name',
  'Last name': 'Last name',
  'Last Report': 'Last Report',
  'Last report': 'Last report',
  'Last updated': 'Last updated',
  'Latest Clinical Alerts': 'Latest Clinical Alerts',
  'Lesser than': 'Lesser than',
  'Lesser than or equal to': 'Lesser than or equal to',
  'Load more': 'Load more',
  'Log a comment that is visible to patient':
    'Log a comment that is visible to patient',
  "Log a note that's only visible to clinical users":
    "Log a note that's only visible to clinical users",
  Logout: 'Logout',
  'View and manage your clinical users and their permissions':
    'View and manage your clinical users and their permissions',
  'Manage dashboard users and permissions.':
    'Manage dashboard users and permissions.',
  Mar: 'Mar',
  March: 'March',
  'Mark as resolved': 'Mark as resolved',
  May: 'May',
  'Message via': 'Message via',
  'minute ago': 'minute ago',
  'Minute(s) after trigger event': 'Minute(s) after trigger event',
  'minutes ago': 'minutes ago',
  'Monitoring Form': 'Monitoring Form',
  'Monitoring form': 'Monitoring form',
  'Monitoring forms': 'Monitoring forms',
  Month: 'Month',
  'month ago': 'month ago',
  'Month(s)': 'Month(s)',
  'Month(s) after reminder': 'Month(s) after reminder',
  'Month(s) after trigger event': 'Month(s) after trigger event',
  'Month(s) before reminder': 'Month(s) before reminder',
  'months ago': 'months ago',
  More: 'More',
  'My Patients': 'My Patients',
  'New reminder': 'New reminder',
  Next: 'Next',
  'Next day': 'Next day',
  'Next month': 'Next month',
  'Next week': 'Next week',
  'No options': 'No options',
  'No report': 'No report',
  'No reports': 'No reports',
  'Select timeframe': 'Select timeframe',
  'No Reports': 'No Reports',
  'No results found': 'No results found',
  'Non-compliant': 'Non-compliant',
  'Non-compliant Patients': 'Non-compliant Patients',
  'Not applicable': 'Not applicable',
  Nov: 'Nov',
  November: 'November',
  'Number of reminders sent': 'Number of reminders sent',
  Oct: 'Oct',
  October: 'October',
  'One-time': 'One-time',
  'One-time reminders do not require a duration to be set.':
    'One-time reminders do not require a duration to be set.',
  'Output format': 'Output format',
  Overview: 'Overview',
  Patient: 'Patient',
  'Patient Details': 'Patient Details',
  'Patient Form': 'Patient Form',
  'Patient ID': 'Patient ID',
  'Patient Information': 'Patient Information',
  'Patient List': 'Patient List',
  'Patient list': 'Patient list',
  'Patient Monitoring': 'Patient Monitoring',
  'Patient name': 'Patient name',
  'Patient Name': 'Patient Name',
  'Patient name A-Z': 'Patient name A-Z',
  'Patient name Z-A': 'Patient name Z-A',
  'Patients assigned': 'Patients assigned',
  Paused: 'Paused',
  'Phone No.': 'Phone No.',
  'Phone number': 'Phone number',
  'Please ensure that the': 'Please ensure that the',
  Previous: 'Previous',
  'Previous day': 'Previous day',
  'Previous month': 'Previous month',
  'Previous week': 'Previous week',
  Private: 'Private',
  Public: 'Public',
  'Reactivate Profile': 'Reactivate Profile',
  'Reassign all patients to': 'Reassign all patients to',
  'Reassign and deactivate user': 'Reassign and deactivate user',
  'Recipient email address': 'Recipient email address',
  Recurring: 'Recurring',
  Reminder: 'Reminder',
  'Reminder end': 'Reminder end',
  'Reminder frequency': 'Reminder frequency',
  'Reminder start': 'Reminder start',
  'Reminder Type': 'Reminder Type',
  Reminders: 'Reminders',
  'reminders start & end dates do not overlap with each other.':
    'reminders start & end dates do not overlap with each other.',
  'Reminders will end': 'Reminders will end',
  'Reminders will start': 'Reminders will start',
  'Remove Clinical User(s) from Alert': 'Remove Clinical User(s) from Alert',
  'Remove Clinical User(s) to Alert': 'Remove Clinical User(s) to Alert',
  Reported: 'Reported',
  Required: 'Required',
  'Reset filters': 'Reset filters',
  Resolved: 'Resolved',
  Role: 'Role',
  'Account Type': 'Account Type',
  Save: 'Save',
  'Save changes?': 'Save changes?',
  Saving: 'Saving',
  'Schedule follow-up reminders': 'Schedule follow-up reminders',
  'Search family member by name or mobile':
    'Search family member by name or mobile',
  'Search patients': 'Search patients',
  'Search Users': 'Search Users',
  'seconds ago': 'seconds ago',
  Select: 'Select',
  'Select a global default or create a custom reminder.':
    'Select a global default or create a custom reminder.',
  'Select data to export': 'Select data to export',
  'Select Family Member(s)': 'Select Family Member(s)',
  'Select message': 'Select message',
  'Select message template': 'Select message template',
  'Select output format': 'Select output format',
  'Select Patient(s)': 'Select Patient(s)',
  'Select Reminder': 'Select Reminder',
  'Select reminder': 'Select reminder',
  'Select role': 'Select role',
  'Select service': 'Select service',
  'Send Message': 'Send Message',
  'Send message': 'Send message',
  'Send now': 'Send now',
  'Send patients a reminder based on a trigger event like enrollment or discharge':
    'Send patients a reminder based on a trigger event like enrollment or discharge',
  'Send patients reminders based on a repeating schedule':
    'Send patients reminders based on a repeating schedule',
  'Send reminder': 'Send reminder',
  'Send template message': 'Send template message',
  'Send to email': 'Send to email',
  Sep: 'Sep',
  September: 'September',
  'Set Date Range': 'Set Date Range',
  'Set reminders every': 'Set reminders every',
  'Set up a new reminder for this patient':
    'Set up a new reminder for this patient',
  'Show data labels': 'Show data labels',
  'Sort patients by': 'Sort patients by',
  Status: 'Status',
  Submission: 'Submission',
  'Submission compliance window': 'Submission compliance window',
  Submissions: 'Submissions',
  Submit: 'Submit',
  'Submitted on (newest to oldest)': 'Submitted on (newest to oldest)',
  'Submitted on (oldest to newest)': 'Submitted on (oldest to newest)',
  'Switch workspace': 'Switch workspace',
  Table: 'Table',
  'The patient will no longer receive reminders':
    'The patient will no longer receive reminders',
  'The patient will receive a message informing them that they have been enrolled into the monitoring program(s)':
    'The patient will receive a message informing them that they have been enrolled into the monitoring program(s)',
  'The patient will receive a message informing them that they have been removed from the monitoring program':
    'The patient will receive a message informing them that they have been removed from the monitoring program',
  'The patient will start receiving reminders':
    'The patient will start receiving reminders',
  "The patient's graphs and submissions will still be available for download under Patient List":
    "The patient's graphs and submissions will still be available for download under Patient List",
  'The user will no longer be able to sign in to the Dashboard.':
    'The user will no longer be able to sign in to the Dashboard.',
  "The user's comments will still be accessible in the Dashboard.":
    "The user's comments will still be accessible in the Dashboard.",
  'Time reported': 'Time reported',
  Today: 'Today',
  'Trigger event': 'Trigger event',
  "Try adjusting your search or filter to find what you're looking for.":
    "Try adjusting your search or filter to find what you're looking for.",
  'Type here': 'Type here',
  'Unread comments': 'Unread comments',
  'Unread Comments': 'Unread Comments',
  'Unresolved Alerts': 'Unresolved Alerts',
  'Update avatar': 'Update avatar',
  'Upon patient discharge': 'Upon patient discharge',
  'User Information': 'User Information',
  'User list': 'User list',
  'View all': 'View all',
  'View all your patient submissions, alerts and non-compliant patients. Click a row to see more details.':
    'View all your patient submissions, alerts and non-compliant patients. Click a row to see more details.',
  "View and manage all your patient's family members.":
    "View and manage all your patient's family members.",
  'Create Patient Groups': 'Create Patient Groups',
  'Choose the hour and minute from the dropdown menu':
    'Choose the hour and minute from the dropdown menu',
  'Patient has been removed from program':
    'Patient has been removed from program',
  'View and manage all patients and their individual settings':
    'View and manage all patients and their individual settings',
  'View as graph': 'View as graph',
  'View as table': 'View as table',
  'View clinical settings': 'View clinical settings',
  'View current reminders': 'View current reminders',
  'View graphs': 'View graphs',
  'View profile': 'View profile',
  'View submissions': 'View submissions',
  Week: 'Week',
  'week ago': 'week ago',
  'Week(s)': 'Week(s)',
  'Week(s) after reminder': 'Week(s) after reminder',
  'Week(s) before reminder': 'Week(s) before reminder',
  'weeks ago': 'weeks ago',
  'Weeks(s) after trigger event': 'Weeks(s) after trigger event',
  Welcome: 'Welcome {{name}}',
  'Well done!': 'Well done!',
  'Download all your clinical user details as a CSV file.':
    'Download all your clinical user details as a CSV file.',
  'What happens when a patient is discharged?':
    'What happens when a patient is discharged?',
  'What happens when a patient is enrolled?':
    'What happens when a patient is enrolled?',
  'What happens when an account is deactivated?':
    'What happens when an account is deactivated?',
  'Would you like to download all users in a CSV file?':
    'Would you like to download all users in a CSV file?',
  'You can individually reassign patients in':
    'You can individually reassign patients in',
  'You have no unresolved alerts!': 'You have no unresolved alerts!',
  'Your changes have not been saved': 'Your changes have not been saved',
  'Your changes have not been saved. Before proceeding, would you like to save or discard changes?':
    'Your changes have not been saved. Before proceeding, would you like to save or discard changes?',

  '2h after meal': '2h After Meal',

  Afternoon: 'Afternoon',

  'Before Bedtime': 'Before Bedtime',
  'Before meal': 'Before Meal',
  'Blood glucose': 'Blood glucose',
  'Blood pressure': 'Blood pressure',

  'Clinical Parameter': 'Clinical Parameter',
  'Clinical User': 'Clinical User',

  Diastolic: 'Diastolic',

  'Edit clinical settings': 'Edit clinical settings',
  'Edit User': 'Edit User',
  'Edit threshold': 'Edit threshold',

  Evening: 'Evening',
  'Glucose Reading': 'Glucose Reading',

  'Heart Rate': 'Heart Rate',

  Morning: 'Morning',

  'No Reports\\nThere were no reports in this date range':
    'No Reports\\nThere were no reports in this date range',

  'Out of range': 'Out of range',

  'Submitted on (first to last)': 'Submitted on (first to last)',
  'Submitted on (last to first)': 'Submitted on (last to first)',
  Systolic: 'Systolic',
  Threshold: 'Threshold',
  Type: 'Type',

  'Alert (Resolved)': 'Alert (Resolved)',
  'Alert (Unresolved)': 'Alert (Unresolved)',

  'Change Profile Picture': 'Change Profile Picture',

  'Last 30 Days': 'Last 30 Days',

  Normal: 'Normal',

  Yesterday: 'Yesterday',

  'Account type': 'Account type',

  'Reset filter': 'Reset filter',
  'Alerts received via': 'Alerts received via',
  'Associate Consultant': 'Associate Consultant',
  'Care Coordinator': 'Care Coordinator',
  Consultant: 'Consultant',
  Nurse: 'Nurse',
  Pharmacist: 'Pharmacist',
  'Senior Consultant': 'Senior Consultant',
  'Senior Nurse': 'Senior Nurse',
  Owner: 'Owner',
  Editor: 'Editor',
  Member: 'Member',
  Viewer: 'Viewer',
  'Bot MD': 'Bot MD',
  SMS: 'SMS',
  'Hospital name': 'Hospital name',
  'Patient Submissions': 'Patient Submissions',
  Analytics: 'Analytics',
  'Welcome back': 'Welcome back',

  'No data available': 'No data available',
  '{{numOfDays}} days ago': '{{numOfDays}} days ago',

  Parameters: 'Parameters',
  'Heart Beat': 'Heart Beat',
  'Oxygen Percentage': 'Oxygen Percentage',
  'Body Temperature': 'Body Temperature',
  '04Aug2021008': '04 Aug 2021 0:08',

  'Add monitoring form': 'Add monitoring form',

  'Phone Number': 'Phone Number',
  'Clinical User Information': 'Clinical User Information',
  'Clinical User In-Charge': 'Clinical User In-Charge',
  'Clinical Alert Recipient 1': 'Clinical Alert Recipient 1',
  'Clinical Alert Recipient 2': 'Clinical Alert Recipient 2',

  Alerts: 'Alerts',
  'Non-Compliant': 'Non-Compliant',
  'Data range': 'Data range',

  'Submitted On': 'Submitted On',
  'Download patient data': 'Download patient data',
  'Report Time': 'Report Time',
  Details: 'Details',
  Replies: 'Replies',
  hb50: 'HB: 50',
  '13Aug1208': '13 Aug, 12:08',

  'Add new comment': 'Add new comment',
  'Alert Thresholds': 'Alert Thresholds',

  Customize: 'Customize',

  Discharge: 'Discharge',
  'Edit reminder': 'Edit reminder',
  Enroll: 'Enroll',

  Form: 'Form',

  'If patient submits within this time window, they are considered compliant.':
    'If patient submits within this time window, they are considered compliant.',

  'Lesser than or equal': 'Lesser than or equal',
  'Monitoring form(s)': 'Monitoring form(s)',

  Oxygen: 'Oxygen',
  'Patient form': 'Patient form',
  'Patient submissions': 'Patient submissions',
  reminder: 'reminder',

  'Select Reminder Frequency': 'Select Reminder Frequency',
  'Send reminders every': 'Send reminders every',
  'Settings will take effect upon save.':
    'Settings will take effect upon save.',
  'Submission window to be compliant': 'Submission window to be compliant',
  Temperature: 'Temperature',

  'Template message': 'Template message',

  Thresholds: 'Thresholds',

  'What time should patients receive reminders':
    'What time should patients receive reminders',

  'Less than': 'Less than',
  'or equal to': 'or equal to',
  'Loading Dashboard ': 'Loading Dashboard ',

  "Apply a filter for the Clinical User's name to view all patients they are assigned to":
    "Apply a filter for the Clinical User's name to view all patients they are assigned to",
  'You are about to mark {{unreadCommentsCount}} unread comment{{plural}} as read. This action cannot be undone.':
    'You are about to mark **{{unreadCommentsCount}} unread comment{{plural}}** as read. This action cannot be undone.',

  'Mark all as resolved dialog message paragraph 1':
    'You are about to mark **{{markAsResolvedCount}} unresolved alert{{plural}}** as resolved. This action cannot be undone. ',
  'Your name and the comment below will be automatically attached to all these alerts':
    'Your name and the comment below will be automatically attached to all these alerts.',
  'Search patients by name or mobile': 'Search patients by name or mobile',
  Maintenance: `BotMD Care will be unavailable for scheduled maintenance from {{startDateTime}} until approximately {{endDateTime}}. The web portal will not be
    available, and patient queries will be processed after the
    scheduled maintenance. We apologize for any inconvenience. Please
    contact <0>support</0> with any questions.`,
};

export default en;
