import React, { useRef } from 'react';
import { Box, Flex, H1, InfoCard, FAIcon, useApi } from '@fivehealth/botero';

import { chain, get } from 'lodash';
import { parseISO, formatDistanceToNowStrict, sub, formatISO } from 'date-fns';

import { es, enGB, id } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  faUser,
  faExclamationTriangle,
  faArrowRight,
  faFrown,
  faComment,
} from '@fortawesome/pro-regular-svg-icons';
import Markdown from 'components/Markdown/Markdown';
import truncateMarkdown from 'lib/truncateMarkdown';
import EVENTS from 'constants/events';
import { convertUrlsIntoMarkDownString } from 'AppUtils';
import EmptyAlertsBotAvatar from '../../assets/empty-alerts-bot-avatar.svg';
import DataTable from './DataTable';
import formatPhoneNumber from '../../lib/formatPhoneNumber';

const Dashboard = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const startDateRef = useRef(new Date());

  const {
    queries: {
      usePatientForms,
      usePatientFormsCount,
      useSubmissionsCount,
      useCurrentUser,
      usePatientsCount,
      useSubmissionsUnresolved,
      useClinicProgramsCount,
    },
  } = useApi({
    queries: [
      'usePatientForms',
      'usePatientFormsCount',
      'useSubmissionsCount',
      'useCurrentUser',
      'usePatientsCount',
      'useSubmissionsUnresolved',
      'useClinicProgramsCount',
    ],
  });

  const getISOLng = (lng) => {
    switch (lng) {
      case 'es':
        return es;
      case 'id':
        return id;
      default:
        return enGB;
    }
  };

  useClinicProgramsCount();

  const { data: clinician } = useCurrentUser();

  const { data: activePatientCount } = usePatientsCount({
    enabled: true,
    variables: { deactivatedOn_Isnull: true },
  });

  const { data: unreadCommentsCount } = useSubmissionsCount({
    variables: {
      hasUnseenCommentsAndVisibleToEverybody: true,
      // NOTE: CARE-1364: Commented out below as we dont need this params as per requirement
      // hasUnseenComments: true,
      // hasCommentVisibleTo: COMMENT_VISIBILITY.public,
    },
  });

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const { data: nonCompliantData } = usePatientForms({
    variables: {
      activated: true,
      nonCompliantSince: formatISO(sub(startDateRef.current, { days: 1 })),
      first: 5,
    },
  });

  const { data: nonCompliantPatientCount } = usePatientFormsCount({
    variables: {
      activated: true,
      nonCompliantSince: formatISO(sub(startDateRef.current, { days: 1 })),
    },
  });

  const nonCompliantPatients = get(nonCompliantData, 'pages', [])
    .flatMap((data) => data || [])
    .filter(Boolean);

  const { data: alertsCount } = useSubmissionsCount({
    variables: {
      isAlertsTriggered: true,
      isAlertsResolved: false,
    },
  });

  const { data: alertData } = useSubmissionsUnresolved({
    variables: {
      orderField: 'SUBMITTED_ON',
      orderDesc: true,
      isAlertsTriggered: true,
      isAlertsResolved: false,
      first: 5,
    },
  });

  const alerts = chain(alertData)
    .get('pages', [])
    .flatMap((data) => data)
    .map((alert) => ({
      ...alert,
      deactivated: get(alert, 'patient.deactivatedOn'),
    }))
    .value();

  const InfoCardStats = {
    activePatientCount,
    alertsCount,
    nonCompliantPatientCount,
    unreadCommentsCount,
  };

  const nonCompliantList = nonCompliantPatients.map((data) => ({
    data,
    patient: data?.patient?.name,
    contact: formatPhoneNumber(data?.patient?.phone),
    lastReport: get(data, 'lastSubmission.uid')
      ? formatDistanceToNowStrict(
          parseISO(get(data, 'lastSubmission.submittedOn')),
          {
            addSuffix: true,
            locale: getISOLng(clinician?.metadata?.language),
          }
        )
      : '-',
  }));

  const renderAlertCell = (col, row) => {
    const isDischarge = get(row, 'patientForm.isDischarged');
    switch (col.key) {
      case 'name':
        return `${get(row, 'name')}${
          row.deactivated || isDischarge ? ` (${t('Discharged')})` : ''
        }`;
      case 'reported':
        if (get(row, 'triggeredAlerts[0].createdOn')) {
          return formatDistanceToNowStrict(
            parseISO(row.triggeredAlerts[0].createdOn),
            {
              addSuffix: true,
              locale: getISOLng(clinician?.metadata?.language),
            }
          );
        }
        return '';
      case 'submissionParameters': {
        return (
          <Markdown>
            {truncateMarkdown(
              convertUrlsIntoMarkDownString(get(row, 'submissionParameters')),
              {
                limit: 100,
                ellipsis: true,
              }
            )}
          </Markdown>
        );
      }

      default:
        return null;
    }
  };

  const handleRowPressed = (path, patient) => {
    history.push({
      pathname: path,
      state: {
        patient,
      },
    });
  };

  return (
    <Box mt={2}>
      {clinician && (
        <H1
          mb={isMobile ? '0px' : '24px'}
          fontSize={isMobile ? '24px' : '32px'}
        >
          {t('Welcome', { name: clinician?.name })}!
        </H1>
      )}

      <Flex
        pb={1}
        mb={[3, 0]}
        flexDirection={['column', 'column', 'row']}
        flexWrap="wrap"
      >
        <InfoCard
          data-testid="info_card_box_menu_alerts"
          logEventProps={{
            page: 'Overview',
            eventName: EVENTS.LIST_CLINICAL_ALERTS,
          }}
          onClick={() => history.push('/patient-monitoring/alerts')}
          mr={[0, 0, 6]}
          mt={3}
          stats={InfoCardStats?.alertsCount || 0}
          icon={
            <FAIcon
              icon={faExclamationTriangle}
              style={{ fontSize: 20, color: '#fff' }}
            />
          }
          variant="danger"
          flexDirection={['row', 'row', 'column']}
          title={
            <Flex alignItems="center">
              {t('Clinical Alerts')}
              <FAIcon
                icon={faArrowRight}
                style={{ marginLeft: 4, fontSize: 13, color: '#697481' }}
              />
            </Flex>
          }
        />
        <InfoCard
          data-testid="info_card_box_menu_noncompliants"
          mr={[0, 0, 6]}
          logEventProps={{
            page: 'Overview',
            eventName: EVENTS.LIST_NON_COMPLIANT_PATIENTS,
          }}
          mt={3}
          onClick={() => history.push('/patient-monitoring/non-compliant')}
          stats={InfoCardStats?.nonCompliantPatientCount || 0}
          icon={
            <FAIcon icon={faFrown} style={{ fontSize: 20, color: '#fff' }} />
          }
          variant="warning"
          flexDirection={['row', 'row', 'column']}
          title={
            <Flex alignItems="center">
              {t('Non-compliant Patients')}
              <FAIcon
                icon={faArrowRight}
                style={{ marginLeft: 4, fontSize: 13, color: '#697481' }}
              />
            </Flex>
          }
        />
        <InfoCard
          data-testid="info_card_box_menu_unread_comments"
          mr={[0, 0, 6]}
          logEventProps={{
            page: 'Overview',
            eventName: EVENTS.LIST_ALL_UNREAD_COMMENTS,
          }}
          onClick={() => history.push('/patient-monitoring/unread-comments')}
          mt={3}
          stats={InfoCardStats?.unreadCommentsCount || 0}
          icon={
            <FAIcon icon={faComment} style={{ fontSize: 20, color: '#fff' }} />
          }
          variant="primary"
          flexDirection={['row', 'row', 'column']}
          title={
            <Flex alignItems="center">
              {t('Unread comments')}
              <FAIcon
                icon={faArrowRight}
                style={{ marginLeft: 4, fontSize: 13, color: '#697481' }}
              />
            </Flex>
          }
        />
        <InfoCard
          data-testid="info_card_box_menu_all_active"
          logEventProps={{
            page: 'Overview',
            eventName: EVENTS.LIST_ALL_ACTIVE_PATIENTS,
          }}
          onClick={() => history.push('/patient-list')}
          mt={3}
          stats={InfoCardStats?.activePatientCount || 0}
          icon={
            <FAIcon icon={faUser} style={{ fontSize: 20, color: '#fff' }} />
          }
          style={{
            backgroundColor: '#F4F6F8',
          }}
          variant="#A3A9B1"
          flexDirection={['row', 'row', 'column']}
          title={
            <Flex alignItems="center">
              {t('Active Patients')}
              <FAIcon
                icon={faArrowRight}
                style={{ marginLeft: 4, fontSize: 13, color: '#697481' }}
              />
            </Flex>
          }
        />
      </Flex>

      <Flex
        mt={8}
        pb={8}
        flex={1}
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        {alerts && (
          <DataTable
            data-testid="data_table_overview_unresolved_alerts"
            logEventProps={{
              page: 'Overview',
              subSource: 'Overview',
              eventName: EVENTS.LIST_UNRESOLVED_ALERTS,
              rowClickedEvent: 'Individual Alert Clicked',
            }}
            title={t('Latest Clinical Alerts')}
            tableHeaders={[
              { key: 'name', label: t('Patient') },
              { key: 'reported', label: t('Reported') },
              { key: 'submissionParameters', label: t('Submission') },
            ]}
            rows={alerts}
            renderCell={renderAlertCell}
            pr={[0, 0, '20px']}
            mb={[4, 4, 0]}
            width={['100%', '80%', '50%']}
            handleTitleClick={() => history.push('/patient-monitoring/alerts')}
            noResultProps={{
              title: '',
              description: t('You have no alerts'),
              avatar: EmptyAlertsBotAvatar,
            }}
            handleRowPressed={(row) => {
              handleRowPressed('/patient-monitoring/alerts', row);
            }}
          />
        )}
        <DataTable
          data-testid="data_table_overview_non_compliants"
          logEventProps={{
            page: 'Overview',
            subSource: 'Overview',
            eventName: EVENTS.LIST_NON_COMPLIANT_PATIENTS,
            rowClickedEvent: 'Individual Alert Clicked',
          }}
          title={t('Non-compliant Patients')}
          tableHeaders={[
            { key: 'patient', label: t('Patient') },
            { key: 'contact', label: t('Contact') },
            {
              key: 'lastReport',
              label: t('Last Report'),
            },
          ]}
          rows={nonCompliantList}
          mb={[4, 4, 0]}
          width={['100%', '80%', '50%']}
          handleTitleClick={() =>
            history.push('/patient-monitoring/non-compliant')
          }
          noResultProps={{
            title: t('No Reports'),
            description: t('All patients compliant'),
            avatar: EmptyAlertsBotAvatar,
          }}
          handleRowPressed={(row) => {
            handleRowPressed(`/patient-list/${row.data.patient.uid}`);
          }}
        />
      </Flex>
    </Box>
  );
};

export default Dashboard;
