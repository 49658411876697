import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { get } from 'lodash';
import { buildPaginatedResponse } from 'lib/pagination';
import { endOfDay, formatISO, startOfDay, sub } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientGroups(
    $orderField: PatientGroupOrderBy
    $first: Int
    $offset: Int
    $orderDesc: Boolean
    $search: String
    $clinicianIcUid: [String]
    $isSystemGroup: Boolean
    $createdOnGte: DateTime
  ) {
    cleoPatientGroups(
      sortBy: $orderField
      first: $first
      offset: $offset
      sortDesc: $orderDesc
      search: $search
      createdBy_Uid_In: $clinicianIcUid
      isSystemGroup: $isSystemGroup
      createdOn_Gte: $createdOnGte
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          uid
          name
          isFixed
          patientCount
          createdBy {
            name
          }
          description
          isAllPatients
          createdOn
          isSystemGroup
          monitoringFormsets {
            edges {
              node {
                monitoringForms {
                  name
                  effectiveName
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const today = moment(moment().format('YYYY-MM-DDTHH:00:00')).toDate();

export const dateRange = [
  {
    id: 'today',
    label: 'Today',
    toParams: {
      createdOnGte: formatISO(endOfDay(sub(today, { days: 1 }))),
    },
  },
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
    toParams: {
      createdOnGte: formatISO(startOfDay(sub(today, { days: 6 }))),
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    toParams: {
      createdOnGte: formatISO(startOfDay(sub(today, { days: 29 }))),
    },
  },
];

const usePatientGroups = () => (args) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const patientGroups = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patientsGroups',
    paginate: 'offset',
    filters: {
      dateRange: { id: 'dateRange', title: t('Created On'), data: dateRange },
      groupType: {
        id: 'groupType',
        title: t('Group type'),
        data: [
          {
            id: 'custom',
            label: t('Custom groups'),
            toParams: {
              isSystemGroup: false,
            },
          },
          {
            id: 'isDynamic',
            label: t('System groups'),
            toParams: {
              isSystemGroup: true,
            },
          },
        ],
      },
    },
    getNextPageParam: ({ cleoPatientGroups: data } = {}, allPages) => {
      const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
      if (!hasNextPage) {
        return undefined;
      }
      const currentOffset = allPages.flatMap(({ cleoPatientGroups }) =>
        get(cleoPatientGroups, 'edges', [])
      ).length;
      return currentOffset;
    },
    select: (data) => buildPaginatedResponse(data, 'cleoPatientGroups'),
    keepPreviousData: true,
  });
  return patientGroups(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientGroups();
