import {
  Box,
  DataTable,
  FAIcon,
  Flex,
  useApi,
  H5,
  H1,
  SecondaryOutlinedButton,
  Body,
  Text,
  PrimaryButton,
  DangerButton,
  H2,
} from '@fivehealth/botero';
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import {
  charPluralize,
  getMessageDeliveriesExport,
  getPatientFormsExport,
  getSubmissionsExport,
  tableConfigUtils,
} from 'AppUtils';
import SearchBar from 'components/Table/SearchBar';
import TableLoader from 'components/Table/TableLoader';
import { Colors, EXPORT_DATA_TYPE } from 'constants';
import { useAppData } from 'context/AppDataContext';
import { useErrorModal } from 'context/ErrorModalContext';
import { useModal } from 'context/ModalContext';
import { get, isEmpty, isNull } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { ACTIVE_PATIENTS_CONFIG } from 'views/PatientsList/defaultConfigs';
import PatientList from 'views/PatientsList/PatientsList';
import ExportData, { defaultExportDataSettings } from 'components/ExportData';
import EVENTS from 'constants/events';
import { useQueryClient } from 'react-query';
import { useCookies } from 'react-cookie';
import Config from 'Config';
import CreateGroupSuccessModal from './GroupCreationSuccessModal';

const EditGroupMembers = ({
  selectedGroup,
  toggleView,
  viewMode,
  toggleEditMode,
}) => {
  const isDynamicGroup = useMemo(
    () => !!selectedGroup?.isSystemGroup,
    [selectedGroup?.isSystemGroup]
  );

  const defaultGQLVariable = useMemo(() => {
    if (isDynamicGroup) {
      return {
        monitoringFormUidIn: [
          get(
            selectedGroup.monitoringFormsets.edges,
            '[0].node.monitoringForms[0].uid',
            ''
          ),
        ],
      };
    }
    return null;
  }, [isDynamicGroup, selectedGroup.monitoringFormsets.edges]);

  const { t } = useTranslation();
  const { sidebarOpened } = useAppData();

  const {
    client,
    queries: {
      useClinic,
      usePatientGroupsUpdate,
      useSubmissionsExportedFormats,
      usePatientFormsExportedFormats,
      useDeliveriesExportedFormats,
      usePatients,
    },
  } = useApi({
    queries: [
      'useClinic',
      'usePatientGroupsUpdate',
      'useSubmissionsExportedFormats',
      'usePatientFormsExportedFormats',
      'useDeliveriesExportedFormats',
      'usePatients',
    ],
  });

  const queryClient = useQueryClient();

  const [cookies] = useCookies([Config.cookie.name]);
  const token = cookies && cookies[Config.cookie.name];

  const { data: exportDataFormatsPatientForms } =
    usePatientFormsExportedFormats({
      enabled: true,
    });

  const { data: exportDataFormatsSubmissions } = useSubmissionsExportedFormats({
    enabled: true,
  });

  const { data: deliveriesExportedFormats } = useDeliveriesExportedFormats({
    enabled: true,
  });

  const { mutateAsync: updateGroupInfo, isLoading: updatingGroup } =
    usePatientGroupsUpdate({
      variables: {},
    });

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const [search, setSearch] = useState('');

  const toggleAllRowsSelectedRef = useRef();
  const [rowsSelectedCount, setRowsSelectedCount] = useState(0);

  const [selectedRows, setSelectedRows] = useState([]);
  const [removedSelection, setRemovedSelection] = useState([]);

  const { openModal, closeModal } = useModal();

  const { updateErrorState } = useErrorModal();

  const { data: clinic } = useClinic({});

  const getTableConfig = useCallback(() => {
    if (isEmpty(clinic?.activePatientListTableSettings)) {
      return ACTIVE_PATIENTS_CONFIG;
    }
    return clinic?.activePatientListTableSettings;
  }, [clinic?.activePatientListTableSettings]);

  const tableConfigSettings = getTableConfig();

  const patientListRef = useRef();

  const columns = useMemo(
    () => [
      ...tableConfigUtils.mapCellRenderToConfig(tableConfigSettings, clinic, t),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clinic, t, tableConfigSettings, viewMode]
  );

  const [autoResetSelectedRows, setAutoResetSelectedRows] = useState(false);

  const [showAddMembers, setShowAddMembers] = useState(false);

  const {
    data: patientsData,
    refetch: refetchPatientsList,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = usePatients({
    enabled: !!selectedGroup.uid,
    variables: {
      groupsIn: [selectedGroup.uid],
      first: 10,
      search,
    },
  });

  const data = useMemo(
    () =>
      get(patientsData, 'pages', [])
        .flatMap((page) => page)

        .filter(Boolean),
    [patientsData]
  );

  const onSearch = (text) => {
    setSearch(text);
  };
  const lastUpdated = useRef(new Date());

  const handleTableRowClick = (row) => {
    if (row?.original?.disableCheckbox) return;
    if (viewMode) return;

    setAutoResetSelectedRows(false);

    const index = removedSelection
      ? removedSelection.findIndex(
          (selectedRow) => selectedRow.original.uid === row.original.uid
        )
      : -1;

    const selectedRowIndex = selectedRows
      ? selectedRows.findIndex(
          (selectedRow) => selectedRow.original.uid === row.original.uid
        )
      : -1;

    if (row.isSelected) {
      setRemovedSelection([...removedSelection, row]);
      setRowsSelectedCount(rowsSelectedCount - 1);

      selectedRows.splice(selectedRowIndex, 1);
      setSelectedRows(selectedRows);
    } else {
      removedSelection.splice(index, 1);
      setRemovedSelection([...removedSelection]);
      setRowsSelectedCount(rowsSelectedCount + 1);
      setSelectedRows([...selectedRows, row]);
    }

    row.toggleRowSelected();
  };

  const resetSelectedRows = useCallback(() => {
    setRowsSelectedCount(0);
    setSelectedRows([]);
    setRemovedSelection([]);
  }, []);

  const cancelSelectedRows = useCallback(
    (toggleEdit = false) => {
      setAutoResetSelectedRows(true);
      if (toggleAllRowsSelectedRef.current) {
        toggleAllRowsSelectedRef.current(false);
      }
      resetSelectedRows();
      if (toggleEdit) {
        toggleEditMode();
      }
    },
    [toggleEditMode, resetSelectedRows]
  );

  const removePatientsFromGroup = useCallback(async () => {
    const removePatients = selectedRows.map((r) => r.id);
    await updateGroupInfo({
      input: {
        removePatients,
        uid: selectedGroup.uid,
      },
    }).then(({ cleoPatientGroupUpdate }) => {
      if (isNull(cleoPatientGroupUpdate)) {
        updateErrorState();
        return;
      }
      refetchPatientsList();
      cancelSelectedRows(true);
    });
  }, [
    selectedRows,
    selectedGroup.uid,
    updateGroupInfo,
    refetchPatientsList,
    cancelSelectedRows,
    updateErrorState,
  ]);

  const confirmPatientRemoval = useCallback(() => {
    openModal(
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <H2>{t('Remove from group')}?</H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>
        <Text fontWeight={400} fontSize={2} mb={2}>
          {t('Are you sure you want to remove these members from the group?')}
        </Text>
        <Text mb={4}>{t(' You can add them back anytime.')}</Text>
        <Flex justifyContent="end">
          <SecondaryOutlinedButton mr={2} onClick={closeModal}>
            {t('Cancel')}
          </SecondaryOutlinedButton>
          <DangerButton
            onClick={() => {
              closeModal();
              removePatientsFromGroup();
            }}
          >
            {t('Yes, remove members')}
          </DangerButton>
        </Flex>
      </Box>,
      {
        style: {
          width: isMobile ? '100%' : '30%',
        },
      }
    );
  }, [removePatientsFromGroup, closeModal, isMobile, openModal, t]);

  const renderRowSelectInfo = useCallback(
    ({ toggleAllRowsSelected }) => {
      toggleAllRowsSelectedRef.current = toggleAllRowsSelected;

      return (
        <Flex
          mr={3}
          style={{
            borderRadius: 5,
            position: 'fixed',
            bottom: '24px',
            width: sidebarOpened ? '80%' : '91%',
          }}
          p={2}
          justifyContent="space-between"
          alignItems="center"
          bg="lightBlue"
        >
          <Flex alignItems="center">
            <Text color="primary" fontWeight="bold" mr={1}>
              {rowsSelectedCount} {t('patients')} {t('selected')}
            </Text>

            <SecondaryOutlinedButton
              onClick={() => cancelSelectedRows(false)}
              mr={2}
              bg="white"
            >
              {t('Deselect all')}
            </SecondaryOutlinedButton>
          </Flex>

          <Flex alignItems="center">
            <SecondaryOutlinedButton
              onClick={() => cancelSelectedRows(true)}
              mr={2}
              bg="white"
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            {rowsSelectedCount > 0 && (
              <DangerButton onClick={() => confirmPatientRemoval()}>
                {t('Remove from group')}
              </DangerButton>
            )}
          </Flex>
        </Flex>
      );
    },
    [
      t,
      cancelSelectedRows,
      rowsSelectedCount,
      confirmPatientRemoval,
      sidebarOpened,
    ]
  );

  const onRefresh = () => {
    refetchPatientsList().then(() => {
      lastUpdated.current = new Date();
    });
  };

  // const onRowSelectionCallback = useCallback(async (list) => {
  //   setSavedEnabled(list.length > 0);
  // }, []);

  const addMembersToGroup = useCallback(async () => {
    const {
      patients: selectedPatiens,
      patientFilter,
      count,
    } = await patientListRef.current.getSelectedPatients().then((r) => r);

    const addPatients = selectedPatiens.map((p) => p.id);

    const input = {
      addPatients,
      uid: selectedGroup.uid,
    };

    if (patientFilter) {
      input.patientFilter = patientFilter;
    }

    await updateGroupInfo({
      input,
    }).then(({ cleoPatientGroupUpdate }) => {
      if (isNull(cleoPatientGroupUpdate)) {
        updateErrorState();
        return;
      }
      setShowAddMembers(false);
      refetchPatientsList();
      cancelSelectedRows();
      openModal(
        <CreateGroupSuccessModal
          title={t('Group updated!')}
          content={`${count} ${t(
            `${charPluralize(count, 'patient')} have been added`
          )}`}
          onViewGroup={() => {
            closeModal();
          }}
          closeModal={() => {
            closeModal();
            toggleView();
          }}
          groupName={selectedGroup.name}
        />,
        {
          style: {
            width: isMobile ? '100%' : '50%',
            maxWidth: '720px',
            overflowY: 'visible',
          },
        }
      );
    });
  }, [
    // toggleEditMode,
    cancelSelectedRows,
    toggleView,
    closeModal,
    isMobile,
    openModal,
    refetchPatientsList,
    selectedGroup.name,
    selectedGroup.uid,
    t,
    updateErrorState,
    updateGroupInfo,
  ]);

  const showTable = useMemo(
    () => !isLoading && !updatingGroup,
    [isLoading, updatingGroup]
  );

  const invokeSubmissionsExport = async ({ outputType, exportDestination }) => {
    const { cleoSubmissions } = await getSubmissionsExport(
      queryClient,
      client,
      exportDestination?.email,
      outputType,
      {
        patientGroupUidIn: [selectedGroup?.uid],
      }
    );

    if (!exportDestination?.email) {
      window.open(
        // eslint-disable-next-line no-template-curly-in-string
        cleoSubmissions?.exportByUrl?.replace('${session}', token),
        '_blank'
      );
    }
  };

  const invokePatientFormsExport = async ({
    outputType,
    exportDestination,
  }) => {
    const { cleoPatientForms } = await getPatientFormsExport(
      queryClient,
      client,
      exportDestination?.email,
      outputType,
      {
        patientGroupUidIn: [selectedGroup?.uid],
      }
    );

    if (!exportDestination?.email) {
      window.open(
        // eslint-disable-next-line no-template-curly-in-string
        cleoPatientForms?.exportByUrl?.replace('${session}', token),
        '_blank'
      );
    }
  };

  const invokeMessageDeliveriesExport = useCallback(
    async ({ outputType, exportDestination }) => {
      if (selectedGroup?.uid && token) {
        const { cleoDeliveries } = await getMessageDeliveriesExport(
          queryClient,
          client,
          exportDestination?.email,
          outputType,
          {
            patientGroupUidIn: [selectedGroup?.uid],
          }
        );
        if (!exportDestination?.email) {
          window.open(
            // eslint-disable-next-line no-template-curly-in-string
            cleoDeliveries?.exportByUrl?.replace('${session}', token),
            '_blank'
          );
        }
      }
    },
    [queryClient, client, selectedGroup?.uid, token]
  );

  const exportDataFormats = {
    submissions: {
      formats: exportDataFormatsSubmissions,
      onSubmitCallback: invokeSubmissionsExport,
    },
    patients: {
      formats: exportDataFormatsPatientForms,
      onSubmitCallback: invokePatientFormsExport,
    },
    messages: {
      formats: deliveriesExportedFormats,
      onSubmitCallback: invokeMessageDeliveriesExport,
    },
  };

  const dynamicGroupExportOptions = useMemo(
    () => [
      {
        value: 'patients',
        label: t('All patient form data'),
      },
      {
        value: 'submissions',
        label: t('All patient form submission data'),
      },
      {
        value: 'messages',
        label: t('All message logs'),
      },
    ],
    [t]
  );

  return (
    <Box overflow="scroll" className="scrollbar-invisible">
      {showAddMembers && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              cursor="pointer"
              onClick={() => {
                setShowAddMembers(false);
              }}
              alignItems="center"
              pb={2}
            >
              <Box cursor="pointer" mr="5px" fontSize={12}>
                <FAIcon fontSize={12} icon={faChevronLeft} />
              </Box>
              <H5 color="darkestShade">{t('Back')}</H5>
            </Flex>

            {/* <Box>
              <PrimaryButton
                disabled={!savedEnabled}
                onClick={addMembersToGroup}
              >
                <Body
                  color={Colors.white}
                  fontSize="14px"
                  fontFamily="Inter,sans-serif"
                  fontWeight="500"
                >
                  {t('Save group')}
                </Body>
              </PrimaryButton>
            </Box> */}
          </Flex>

          <H1>{t('Add patients')}</H1>
        </>
      )}

      {!showAddMembers && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              cursor="pointer"
              onClick={toggleView}
              alignItems="center"
              pb={2}
            >
              <Box cursor="pointer" mr="5px" fontSize={12}>
                <FAIcon fontSize={12} icon={faChevronLeft} />
              </Box>
              <H5 color="darkestShade">{t('Back to patient groups')}</H5>
            </Flex>

            <Flex>
              {!isDynamicGroup && (
                <Flex>
                  <PrimaryButton
                    borderRadius="8px"
                    onClick={() => {
                      cancelSelectedRows(true);
                      setShowAddMembers(true);
                    }}
                  >
                    <Body
                      color={Colors.white}
                      fontSize="14px"
                      fontFamily="Inter,sans-serif"
                      fontWeight="500"
                    >
                      {t('Add patients')}
                    </Body>
                  </PrimaryButton>
                </Flex>
              )}
              {/* TODO: Remove this when tested in staging */}
              {!Config.IS_PRODUCTION() && viewMode && (
                <Box ml={2}>
                  <ExportData
                    logEventProps={{
                      page: 'Patient Group - Patient List Export Data',
                      eventName: EVENTS.EXPORT_PATIENT_GROUP_LIST_DATA,
                    }}
                    triggerElementLabel={t('Download data')}
                    modalParams={{
                      title: t('Download patient data'),
                      showBody: true,
                      showExportDataFormats: true,
                      showExportDestination: true,
                    }}
                    dataFormats={exportDataFormats}
                    dataOptions={
                      isDynamicGroup
                        ? dynamicGroupExportOptions
                        : defaultExportDataSettings(t).dataOptions
                    }
                    dataType={EXPORT_DATA_TYPE.patients}
                    isDisabled={
                      isEmpty(exportDataFormatsSubmissions) ||
                      isEmpty(exportDataFormatsPatientForms)
                    }
                  />
                </Box>
              )}
            </Flex>
          </Flex>

          <H1 mt={2} mb={2}>
            {t(viewMode ? 'View group' : 'Edit group')}: {selectedGroup.name}
          </H1>

          <Flex
            mt={5}
            mb={5}
            justifyContent="space-between"
            alignItems="center"
          >
            <SearchBar
              placeholder={t('Search member by name, phone and email')}
              onSearch={onSearch}
              onRefresh={onRefresh}
              isRefreshing={isLoading}
              lastUpdated={lastUpdated.current}
            />

            {viewMode && !isDynamicGroup && (
              <SecondaryOutlinedButton
                onClick={() => {
                  toggleEditMode();
                }}
                startIcon={
                  <FAIcon icon={faEdit} fontSize={14} color="darkestShade" />
                }
                borderRadius="8px"
              >
                <Body
                  fontSize="14px"
                  fontFamily="Inter,sans-serif"
                  fontWeight="500"
                >
                  {t('Edit')}
                </Body>
              </SecondaryOutlinedButton>
            )}
          </Flex>
        </>
      )}

      {isLoading || (updatingGroup && <TableLoader />)}
      {showTable && !showAddMembers && (
        <Box pb={10}>
          <DataTable
            mb={4}
            data={data}
            initialSortBy={{
              orderField: 'name',
              orderDesc: 'desc',
            }}
            t={t}
            isFetchingNextPage={isFetchingNextPage}
            onFetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            showLoadMore={hasNextPage}
            hideHeaderSelectionCheckBox
            showRowSelectedBar={!viewMode}
            enableRowSelect={!viewMode}
            renderRowSelectInfo={renderRowSelectInfo}
            onRowClick={handleTableRowClick}
            autoResetSelectedRows={autoResetSelectedRows}
            columns={columns}
            noResultsProps={{
              avatarProps: { height: '200px', width: '200px' },
            }}
          />
        </Box>
      )}

      {showAddMembers && showTable && !isDynamicGroup && (
        <PatientList
          ref={patientListRef}
          isViewMode={isDynamicGroup}
          patientGroupSelection
          defaultGQLVariable={defaultGQLVariable}
          filterOutUids={data?.map((d) => d.uid)}
          onCancelCallback={toggleView}
          onSavePatientsGroup={addMembersToGroup}
        />
      )}
    </Box>
  );
};

export default EditGroupMembers;
