import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatients($deactivatedOn_Isnull: Boolean) {
    cleoPatients(deactivatedOn_Isnull: $deactivatedOn_Isnull) {
      totalCount
    }
  }
`;

const usePatientsCount = () => (args) => {
  const patients = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'patientsCount',
    select: ({ cleoPatients }) => cleoPatients?.totalCount,
  });
  return patients(args);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePatientsCount();
