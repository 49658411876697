import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query heimdallSession($uid: String) {
    heimdallSession(uid: $uid) {
      id
      uid
      user {
        uid
      }
      scopes
      expiresOn
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'heimdallSession',
  keepPreviousData: true,
  select: ({ heimdallSession }) => heimdallSession,
});
