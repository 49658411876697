/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { isEmpty, omit, get, isEqual, includes, debounce } from 'lodash';
import { useApi } from '@fivehealth/botero';

import { useAppData } from 'context/AppDataContext';
import qsDecoder from 'components/Filter/qs.decoder';

import AllSubmissionsSection from 'views/PatientMonitoring/AllSubmissionsSection';
import { DataType } from 'constants/dataType';

export interface GqlQueryParam {
  [key: string]: string | number | boolean | object | undefined;
  sortBy?: Array<{
    id: string;
    desc: boolean;
  }>;
}

interface TabWithFilterProps {
  children?: React.ReactElement;
  dataType?: string;
  defaultGQLVariable?: object;
  onRefresh?: () => void;
  tableSettings?: {
    orderDesc?: boolean;
    defaultSortField?: string;
  };
  tableSettingsSinglePatient?: {
    orderDesc?: boolean;
    defaultSortField?: string;
  };
  tabRef?: (ref: { onRefresh: () => void }) => void;
  filterOutUids?: string[];
  patientGroupSelection?: boolean;
  isPatientProfile?: boolean;
  onFilterApplied?: (filters: object) => void;
  hasTableConfig?: boolean;
}

const TabWithFilter = memo<TabWithFilterProps>((props) => {
  const {
    children,
    dataType,
    defaultGQLVariable,
    onRefresh: onRefreshCallback,
    tableSettings,
    tableSettingsSinglePatient,
    tabRef,
    filterOutUids,
    patientGroupSelection,
    isPatientProfile,
    onFilterApplied,
  } = props;

  const { user } = useAppData();
  const { t } = useTranslation();
  const lastUpdated = useRef(new Date());

  const [searchText, setSearchText] = useState('');
  const searchTextRef = useRef(searchText);
  const [shouldQuery, setShouldQuery] = useState(
    !get(props, 'hasTableConfig', true)
  );

  const isSubmissionUnresolved = isEqual(
    dataType,
    DataType.SubmissionUnresolved
  );

  const isSubmission = isEqual(dataType, DataType.Submission);
  const isClinician = isEqual(dataType, DataType.Clinician);
  const isPatientForm = isEqual(dataType, DataType.PatientForm);
  const isPatient = isEqual(dataType, DataType.Patient);
  const isPatientGroup = isEqual(dataType, DataType.PatientGroup);

  const DEFAULT_PARAMS = useMemo(
    () => ({
      orderField: isSubmission ? 'SUBMITTED_ON' : 'NAME',
      orderDesc: false,
      first: 10,
      ...defaultGQLVariable,
    }),
    [defaultGQLVariable, isSubmission]
  );

  const defaultParams = useMemo(
    () =>
      isEmpty(window.location.search)
        ? DEFAULT_PARAMS
        : qs.parse(window.location.search.substr(1), { decoder: qsDecoder }),
    [DEFAULT_PARAMS]
  ) as GqlQueryParam;

  // cleanup gql query params
  const cleanParams = useCallback(
    (params: GqlQueryParam, ignoreTableConfig = false) => {
      let variables: GqlQueryParam = params;

      // @ts-ignore
      const tableConfig = user?.isPatientFacing
        ? tableSettingsSinglePatient
        : tableSettings;

      if (!ignoreTableConfig && tableConfig?.orderDesc) {
        variables = {
          ...variables,
          orderDesc: tableConfig?.orderDesc,
        };
      }

      if (!ignoreTableConfig && tableConfig?.defaultSortField) {
        variables = {
          ...variables,
          orderField: tableConfig?.defaultSortField,
        };
      }

      // usePatientForms
      if (isPatientForm && isEqual(variables.orderField, 'SUBMITTED_ON')) {
        variables = {
          ...variables,
          orderField: 'LAST_SUBMITTED_DATE',
        };
      }

      // useSubmissions
      if (
        isSubmission &&
        isEqual(variables.orderField, 'LAST_SUBMITTED_DATE')
      ) {
        variables = {
          ...variables,
          orderField: 'SUBMITTED_ON',
        };
      }

      if (isPatient) {
        variables = {
          ...defaultGQLVariable,
          ...variables,
        };
      }

      if (isPatientProfile) {
        variables = {
          ...variables,
          ...defaultGQLVariable,
        };
      }
      if (isPatientGroup) {
        variables = {
          ...variables,
          orderField: 'CREATED_ON',
        };
      }

      if (isClinician) {
        variables = {
          activated: true,
          ...variables,
        };
      }

      return variables;
    },
    [
      isClinician,
      isPatientForm,
      isSubmission,
      tableSettings,
      tableSettingsSinglePatient,
      // @ts-ignore
      user?.isPatientFacing,
      isPatientProfile,
      defaultGQLVariable,
      isPatientGroup,
      isPatient,
    ]
  );

  const [requestParams, setRequestParams] = useState(
    cleanParams(defaultParams)
  );

  const {
    queries: {
      useClinicians,
      usePatientForms,
      useSubmissions,
      usePatients,
      usePatientGroups,
      useSubmissionsUnresolved,
    },
  } = useApi({
    queries: [
      'useClinicians',
      'usePatientForms',
      'useSubmissions',
      'usePatients',
      'usePatientGroups',
      'useSubmissionsUnresolved',
    ],
  });

  const {
    data: cliniciansData,
    refetch: refetchClinicians,
    hasNextPage: hasCliniciansNextPage,
    fetchNextPage: fetchCliniciansNextPage,
    isFetched: isCliniciansFetched,
    isLoading: isCliniciansLoading,
    isRefetching: isCliniciansRefetching,
    isFetchingNextPage: isCliniciansFetchingNextPage,
    filters: { clinicalUsers: clinicalUserFilters },
  } = useClinicians({
    variables: isClinician
      ? requestParams
      : {
          activated: true,
          first: 75,
        },
  });

  const {
    data: patientGroupsData,
    refetch: refetchPatientGroups,
    isLoading: isPatientGroupsLoading,
    hasNextPage: hasPatientGroupsNextPage,
    fetchNextPage: fetchPatientGroupsNextPage,
    isFetched: isPatientGroupsFetched,
    isRefetching: isPatientGroupsRefetching,
    isFetchingNextPage: isPatientGroupsFetchingNextPage,
    filters: {
      dateRange: patientGroupsDateRangeFilters,
      groupType: groupTypeFilters,
    },
  } = usePatientGroups({
    enabled: isPatientGroup,
    variables: requestParams,
  });

  const patientGroups = useMemo(
    () =>
      get(patientGroupsData, 'pages', [])
        .flatMap((page: object) => page)
        .map((g: object & { isSystemGroup: boolean }) => ({
          ...g,
          disableCheckbox: g.isSystemGroup,
        }))
        .filter(Boolean),
    [patientGroupsData]
  );

  const clinicians = get(cliniciansData, 'pages', [])
    .flatMap((page: object) => page || [])
    .filter(Boolean);

  const {
    data: patientsData,
    refetch: refetchPatients,
    hasNextPage: hasPatientsNextPage,
    fetchNextPage: fetchPatientsNextPage,
    isFetched: isPatientsFetched,
    isLoading: isPatientsLoading,
    isRefetching: isPatientsRefetching,
    isFetchingNextPage: isPatientsFetchingNextPage,
    filters: {
      monitoringFormsets: patientsMonitoringFormsetsFilters,
      genderFilters,
      dateRangeFilters,
      devicesFilters,
    },
  } = usePatients({
    enabled: isPatient,
    filters: { clinicalUsers: clinicalUserFilters },
    variables: isPatient
      ? requestParams
      : {
          activated: true,
          first: 75,
        },
  });

  const debounceFuncCall = debounce((func) => func(), 1000);
  const patients = useMemo(
    () =>
      get(patientsData, 'pages', [])
        .flatMap((page: object) => page || [])
        .filter(
          (patient: { id: string }) => !includes(filterOutUids, patient?.id)
        )
        .filter(Boolean),
    [patientsData, filterOutUids]
  );

  /**
   * When adding to an existing group, we check until we find patients that have not been added
   * if we have a next page
   */
  if (patientGroupSelection && patients?.length === 0 && hasPatientsNextPage) {
    debounceFuncCall(fetchPatientsNextPage);
  }

  const {
    data: patientFormsData,
    refetch: refetchPatientForms,
    hasNextPage: hasPatientFormsNextPage,
    fetchNextPage: fetchPatientFormsNextPage,
    isFetched: isPatientFormsFetched,
    isLoading: isPatientFormsLoading,
    isRefetching: isPatientFormsRefetching,
    isFetchingNextPage: isPatientFormsFetchingNextPage,
    filters: {
      lastReport: lastReportFilters,
      dateRange: patientFormsDateRangeFilters,
      monitoringFormsets: patientFormsMonitoringFormsetsFilters,
      alerts: statusesFilters,
    },
  } = usePatientForms({
    enabled: isPatientForm && shouldQuery,
    filters: { clinicalUsers: clinicalUserFilters },
    variables: requestParams,
  });

  const patientForms = get(patientFormsData, 'pages', [])
    .flatMap((page: object) => page || [])
    .filter(Boolean);

  const {
    data: submissionsData,
    refetch: refetchSubmissions,
    hasNextPage: hasSubmissionsNextPage,
    fetchNextPage: fetchSubmissionsNextPage,
    isFetched: isSubmissionsFetched,
    isLoading: isSubmissionsLoading,
    isRefetching: isSubmissionsRefetching,
    isFetchingNextPage: isSubmissionsFetchingNextPage,
    filters: {
      dateRange: submissionsDateRangeFilters,
      monitoringFormsets: submissionsMonitoringFormsetsFilters,
    },
  } = useSubmissions({
    enabled: isSubmission && shouldQuery,
    filters: { clinicalUsers: clinicalUserFilters },
    variables: requestParams,
    paginate: 'offset',
  });

  const submissions = get(submissionsData, 'pages', [])
    .flatMap((page: object) => page || [])
    .filter(Boolean);

  const {
    data: submissionsUnresolvedData,
    refetch: refetchUnresolvedSubmissions,
    hasNextPage: hasUnresolvedSubmissionsNextPage,
    fetchNextPage: fetchUnresolvedSubmissionsNextPage,
    isFetched: isUnresolvedSubmissionsFetched,
    isLoading: isUnresolvedSubmissionsLoading,
    isRefetching: isUnresolvedSubmissionsRefetching,
    isFetchingNextPage: isUnresolvedSubmissionsFetchingNextPage,
    filters: {
      dateRange: unresolvedSubmissionsDateRangeFilters,
      monitoringFormsets: unresolvedSubmissionsMonitoringFormsetsFilters,
    },
  } = useSubmissionsUnresolved({
    enabled: isSubmissionUnresolved,
    filters: { clinicalUsers: clinicalUserFilters },
    variables: requestParams,
    paginate: 'offset',
  });

  const unresolvedSubmissions = get(submissionsUnresolvedData, 'pages', [])
    .flatMap((page: object) => page || [])
    .filter(Boolean);

  const getFetchDataCount = useCallback((fetchedData: unknown) => {
    const totalFetchDataCount = get(fetchedData, 'totalCount', 0);
    const pageCount: number = get(fetchedData, 'pages.length', 0);
    const pageSize: number = get(fetchedData, 'pages[0].length', 0);

    return {
      totalFetchDataCount,
      pageCount: pageCount * pageSize,
    };
  }, []);

  const getGQLProps = useCallback(() => {
    if (isSubmissionUnresolved) {
      return {
        refetch: refetchUnresolvedSubmissions,
        dateRangeFilters: unresolvedSubmissionsDateRangeFilters,
        fetchNextPage: fetchUnresolvedSubmissionsNextPage,
        hasNextPage: hasUnresolvedSubmissionsNextPage,
        isFetched: isUnresolvedSubmissionsFetched,
        isFetchingNextPage: isUnresolvedSubmissionsFetchingNextPage,
        isLoading: isUnresolvedSubmissionsLoading,
        isRefetching: isUnresolvedSubmissionsRefetching,
        monitoringFormsetsFilters:
          unresolvedSubmissionsMonitoringFormsetsFilters,
        ...getFetchDataCount(submissionsUnresolvedData),
      };
    }

    if (isSubmission) {
      return {
        dateRangeFilters: submissionsDateRangeFilters,
        fetchNextPage: fetchSubmissionsNextPage,
        hasNextPage: hasSubmissionsNextPage,
        isFetched: isSubmissionsFetched,
        isFetchingNextPage: isSubmissionsFetchingNextPage,
        isLoading: isSubmissionsLoading,
        isRefetching: isSubmissionsRefetching,
        monitoringFormsetsFilters: submissionsMonitoringFormsetsFilters,
        ...getFetchDataCount(submissionsData),
      };
    }

    if (isPatientForm) {
      return {
        dateRangeFilters: patientFormsDateRangeFilters,
        fetchNextPage: fetchPatientFormsNextPage,
        hasNextPage: hasPatientFormsNextPage,
        isFetched: isPatientFormsFetched,
        isFetchingNextPage: isPatientFormsFetchingNextPage,
        isLoading: isPatientFormsLoading,
        isRefetching: isPatientFormsRefetching,
        monitoringFormsetsFilters: patientFormsMonitoringFormsetsFilters,
        ...getFetchDataCount(patientFormsData),
      };
    }

    if (isPatient) {
      return {
        fetchNextPage: fetchPatientsNextPage,
        hasNextPage: hasPatientsNextPage,
        isFetched: isPatientsFetched,
        isFetchingNextPage: isPatientsFetchingNextPage,
        isLoading: isPatientsLoading,
        isRefetching: isPatientsRefetching,
        monitoringFormsetsFilters: patientsMonitoringFormsetsFilters,
        ...getFetchDataCount(patientsData),
      };
    }

    if (isClinician) {
      return {
        fetchNextPage: fetchCliniciansNextPage,
        hasNextPage: hasCliniciansNextPage,
        isFetched: isCliniciansFetched,
        isFetchingNextPage: isCliniciansFetchingNextPage,
        isLoading: isCliniciansLoading,
        isRefetching: isCliniciansRefetching,
        ...getFetchDataCount(cliniciansData),
      };
    }

    if (isPatientGroup) {
      return {
        patientGroupsDateRangeFilters,
        groupTypeFilters,
        fetchNextPage: fetchPatientGroupsNextPage,
        hasNextPage: hasPatientGroupsNextPage,
        isFetched: isPatientGroupsFetched,
        isFetchingNextPage: isPatientGroupsFetchingNextPage,
        isLoading: isPatientGroupsLoading,
        isRefetching: isPatientGroupsRefetching,
        ...getFetchDataCount(patientGroupsData),
      };
    }

    return {};
  }, [
    isSubmissionUnresolved,
    isSubmission,
    isPatientForm,
    isPatient,
    isClinician,
    isPatientGroup,
    refetchUnresolvedSubmissions,
    unresolvedSubmissionsDateRangeFilters,
    fetchUnresolvedSubmissionsNextPage,
    hasUnresolvedSubmissionsNextPage,
    isUnresolvedSubmissionsFetched,
    isUnresolvedSubmissionsFetchingNextPage,
    isUnresolvedSubmissionsLoading,
    isUnresolvedSubmissionsRefetching,
    unresolvedSubmissionsMonitoringFormsetsFilters,
    getFetchDataCount,
    submissionsUnresolvedData,
    submissionsDateRangeFilters,
    fetchSubmissionsNextPage,
    hasSubmissionsNextPage,
    isSubmissionsFetched,
    isSubmissionsFetchingNextPage,
    isSubmissionsLoading,
    isSubmissionsRefetching,
    submissionsMonitoringFormsetsFilters,
    submissionsData,
    patientFormsDateRangeFilters,
    fetchPatientFormsNextPage,
    hasPatientFormsNextPage,
    isPatientFormsFetched,
    isPatientFormsFetchingNextPage,
    isPatientFormsLoading,
    isPatientFormsRefetching,
    patientFormsMonitoringFormsetsFilters,
    patientFormsData,
    fetchPatientsNextPage,
    hasPatientsNextPage,
    isPatientsFetched,
    isPatientsFetchingNextPage,
    isPatientsLoading,
    isPatientsRefetching,
    patientsMonitoringFormsetsFilters,
    patientsData,
    fetchCliniciansNextPage,
    hasCliniciansNextPage,
    isCliniciansFetched,
    isCliniciansFetchingNextPage,
    isCliniciansLoading,
    isCliniciansRefetching,
    cliniciansData,
    patientGroupsDateRangeFilters,
    groupTypeFilters,
    fetchPatientGroupsNextPage,
    hasPatientGroupsNextPage,
    isPatientGroupsFetched,
    isPatientGroupsFetchingNextPage,
    isPatientGroupsLoading,
    isPatientGroupsRefetching,
    patientGroupsData,
  ]);

  const onFetchData = useCallback(
    (params: GqlQueryParam, includeRequestParams = true) => {
      const { sortBy: [sortBy] = [] } = params;

      let newRequestParam = includeRequestParams
        ? {
            ...requestParams,
            ...params,
          }
        : params;

      // removing unrelated key to prevent refetching of data on gql side
      // sortBy, pageSize, pageIndex are the key for react-table, not for gql qeury param
      newRequestParam = omit(newRequestParam, [
        'sortBy',
        'pageSize',
        'pageIndex',
      ]);

      /* Commenting this out because it's causing an issue where the first applied filter does not filter the list */
      // // Fix for table calling onFetchData during init which overwrite the request params
      // if (isInitialLoading) {
      //   if (
      //     isSubmissionsFetched ||
      //     isPatientFormsFetched ||
      //     isCliniciansFetched ||
      //     isEqual(dataType, DataType.Null)
      //   ) {
      //     setIsInitialLoading(false);
      //   }
      //   return;
      // }

      if (sortBy?.id) {
        newRequestParam = {
          ...newRequestParam,
          orderField: sortBy.id,
          orderDesc: sortBy?.desc === true,
        };
      }
      setRequestParams(cleanParams(newRequestParam, true));
    },
    [cleanParams, requestParams]
  );

  const onApplyFilters = useCallback(
    (filters: GqlQueryParam) => {
      const currentParams = qs.parse(window.location.search.substr(1), {
        decoder: qsDecoder,
      }) as GqlQueryParam;

      // Remove all params except search
      Object.keys(currentParams).forEach((key) => {
        if (get(currentParams, key) !== searchTextRef.current) {
          delete currentParams[key];
        }
      });

      onFetchData({ ...currentParams, ...filters }, false);
      if (onFilterApplied) {
        onFilterApplied({ ...currentParams, ...filters });
      }
    },
    [onFetchData, onFilterApplied]
  );

  const onResetFilters = useCallback(() => {
    let param = cleanParams(DEFAULT_PARAMS);

    // to retain query from searchbar
    if ((isSubmission || isPatientForm) && requestParams?.patientName) {
      param = {
        ...param,
        patientName: requestParams?.patientName,
      };
    }
    if (isClinician && requestParams?.clinicianNameContains) {
      param = {
        ...param,
        clinicianNameContains: requestParams?.clinicianNameContains,
      };
    }

    if ((isPatient || isPatientGroup) && requestParams?.search) {
      param = {
        ...param,
        search: requestParams?.search,
      };
    }

    setRequestParams(param);
    // setShowFilters(false);
  }, [
    DEFAULT_PARAMS,
    cleanParams,
    isClinician,
    isPatient,
    isPatientForm,
    isSubmission,
    requestParams?.clinicianNameContains,
    requestParams?.patientName,
    requestParams?.search,
    isPatientGroup,
  ]);

  const onRefresh = useCallback(async () => {
    if (isSubmission) {
      return refetchSubmissions().then(async () => {
        lastUpdated.current = new Date();
        if (onRefreshCallback) {
          await onRefreshCallback();
        }
      });
    }

    if (isPatientForm) {
      return refetchPatientForms().then(async () => {
        lastUpdated.current = new Date();
        if (onRefreshCallback) {
          await onRefreshCallback();
        }
      });
    }

    if (isPatient) {
      return refetchPatients().then(async () => {
        lastUpdated.current = new Date();
        if (onRefreshCallback) {
          await onRefreshCallback();
        }
      });
    }

    if (isClinician) {
      return refetchClinicians().then(async () => {
        lastUpdated.current = new Date();
        if (onRefreshCallback) {
          await onRefreshCallback();
        }
      });
    }

    if (isPatientGroup) {
      return refetchPatientGroups().then(async () => {
        lastUpdated.current = new Date();
        if (onRefreshCallback) {
          await onRefreshCallback();
        }
      });
    }

    if (isSubmissionUnresolved) {
      return refetchUnresolvedSubmissions().then(async () => {
        lastUpdated.current = new Date();
        if (onRefreshCallback) {
          await onRefreshCallback();
        }
      });
    }

    if (onRefreshCallback) {
      return onRefreshCallback();
    }

    return null;
  }, [
    isClinician,
    isPatient,
    isPatientForm,
    isPatientGroup,
    isSubmission,
    isSubmissionUnresolved,
    onRefreshCallback,
    refetchClinicians,
    refetchPatientForms,
    refetchPatientGroups,
    refetchPatients,
    refetchSubmissions,
    refetchUnresolvedSubmissions,
  ]);

  const onSearch = useCallback(
    (text: string) => {
      let newRequestParams = requestParams;
      setSearchText(text);
      searchTextRef.current = text;
      if (isClinician) {
        if (text) {
          newRequestParams = {
            ...newRequestParams,
            clinicianNameContains: text,
          };
        } else {
          newRequestParams = omit(newRequestParams, 'clinicianNameContains');
        }
      }

      if (isSubmission || isPatientForm || isSubmissionUnresolved) {
        if (text) {
          newRequestParams = {
            ...newRequestParams,
            patientName: text,
          };
        } else {
          newRequestParams = omit(newRequestParams, 'patientName');
        }
      }

      if (isPatient || isPatientGroup) {
        if (text) {
          newRequestParams = {
            ...newRequestParams,
            search: text,
          };
        } else {
          newRequestParams = omit(newRequestParams, 'search');
        }
      }

      const updateQsParams = debounce(() => {
        setRequestParams(newRequestParams);
      }, 500);
      updateQsParams();
    },
    [
      requestParams,
      isClinician,
      isSubmission,
      isPatientForm,
      isSubmissionUnresolved,
      isPatient,
      isPatientGroup,
    ]
  );

  useEffect(() => {
    const queryParams = qs.stringify(requestParams, { encodeValuesOnly: true });
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${queryParams}`
    );
  }, [requestParams]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (tableSettings || tableSettingsSinglePatient) {
      setRequestParams(cleanParams(requestParams));
      setShouldQuery(true);
    }
  }, [cleanParams, tableSettings, tableSettingsSinglePatient]);

  useEffect(() => {
    if (tabRef) {
      tabRef({ onRefresh });
    }
  }, [tabRef]);

  return (
    <>
      {/* ts-ignore */}
      {React.cloneElement(children || <AllSubmissionsSection />, {
        ...props,
        ...getGQLProps(),
        t,
        lastUpdated,
        requestParams,

        // data
        clinicians,
        submissions,
        patientForms,
        patients,
        patientGroups,
        unresolvedSubmissions,

        // Search bar
        searchText,
        setSearchText,

        // utils
        onSearch,
        onRefresh,
        onFetchData,

        // gql filter
        lastReportFilters,
        clinicalUserFilters,
        genderFilters,
        devicesFilters,
        dateRangeFilters,
        statusesFilters,
        patientGroupsDateRangeFilters,

        // filter
        // showFilters,
        // onFilterOpen,
        // onFilterCancel,
        onApplyFilters,
        onResetFilters,
        isPatientProfile,
      })}
    </>
  );
});

export default TabWithFilter;
