import { gql } from 'graphql-request';
import { get } from 'lodash';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoPatientForms(
    $orderField: PatientFormOrderBy
    $orderDesc: Boolean
    $before: String
    $after: String
    $first: Int
    $offset: Int
    $patientIds: [String]
    $nonCompliantSince: DateTime
    $activated: Boolean
  ) {
    cleoPatientForms(
      sortBy: $orderField
      sortDesc: $orderDesc
      first: $first
      before: $before
      after: $after
      offset: $offset
      patient_Uid_In: $patientIds
      nonCompliantSince: $nonCompliantSince
      deactivatedOn_Isnull: $activated
    ) {
      totalCount
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'patientFormsCountMonitoring',
  select: (data) => get(data, 'cleoPatientForms.totalCount'),
});
