import React from 'react';
import { get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Text } from '@fivehealth/botero';

import EVENTS from 'constants/events';
import { EventPage } from 'constants';
import { tableConfigUtils } from 'AppUtils';

import ActionDropdown from 'components/ActionDropdown/ActionDropdown';
import SubmissionParameters from 'components/SubmissionParameters/SubmissionParameters';
import Table from 'components/Table/Table';
import TableLoader from 'components/Table/TableLoader';

import TableCell from './TableCell';

export const SubmissionCell = (props) => {
  const { parsedValueTemplate, value: submissionParameters } = props;

  return (
    <TableCell {...props} style={props.column?.style}>
      <Text fontSize={14}>
        {isEmpty(submissionParameters) ? (
          '-'
        ) : (
          <SubmissionParameters truncate={{ limit: 100, ellipsis: true }}>
            {parsedValueTemplate}
          </SubmissionParameters>
        )}
      </Text>
    </TableCell>
  );
};

export const ActionsCell = ({
  t,
  cell,
  label,
  onShowPatientForm,
  onDownloadSubmissions,
  onSendMessage,
  isSinglePatientView,
  clinician,
  eventSubSource,
}) => {
  const menuList = [
    {
      key: 'patient_monitoring_action_menu_view_submissions',
      testid: 'patient_monitoring_action_menu_view_submissions',
      label: t('View submissions'),
      visible: !isSinglePatientView,
      logEventProps: {
        page: EventPage.PatientMonitoring,
        subSource: eventSubSource,
        eventName: EVENTS.CLICK_SEND_MESSAGE,
      },
      onClick: () =>
        onShowPatientForm(get(cell, 'row.original', {}), 'submissions'),
    },
    {
      key: 'patient_monitoring_action_menu_view_profile',
      testid: 'patient_monitoring_action_menu_view_profile',
      label: t('View profile'),
      logEventProps: {
        page: EventPage.PatientMonitoring,
        subSource: eventSubSource,
        eventName: EVENTS.CLICK_SEND_MESSAGE,
      },
      onClick: () => onShowPatientForm(get(cell, 'row.original', {})),
    },
    {
      key: 'patient_monitoring_action_menu_view_graphs',
      testid: 'patient_monitoring_action_menu_view_graphs',
      label: t('View graphs'),
      logEventProps: {
        page: EventPage.PatientMonitoring,
        subSource: eventSubSource,
        eventName: EVENTS.CLICK_SEND_MESSAGE,
      },
      onClick: () => onShowPatientForm(get(cell, 'row.original', {}), 'graphs'),
    },
    {
      key: 'patient_monitoring_action_menu_view_clinical_settings',
      testid: 'patient_monitoring_action_menu_view_clinical_settings',
      label: t('View clinical settings'),
      visible: !isSinglePatientView,
      logEventProps: {
        page: EventPage.PatientMonitoring,
        subSource: eventSubSource,
        eventName: EVENTS.CLICK_SEND_MESSAGE,
      },
      onClick: () =>
        onShowPatientForm(get(cell, 'row.original', {}), 'clinical-settings'),
    },
    {
      key: 'patient_monitoring_action_menu_download_submissions',
      testid: 'patient_monitoring_action_menu_download_submissions',
      label: t('Download data'),
      visible: !isSinglePatientView && onDownloadSubmissions,
      logEventProps: {
        page: EventPage.PatientMonitoring,
        subSource: eventSubSource,
        eventName: EVENTS.CLICK_SEND_MESSAGE,
      },
      onClick: () => onDownloadSubmissions(get(cell, 'row.original', {})),
    },
    {
      key: 'patient_monitoring_action_menu_send_message',
      testid: 'patient_monitoring_action_menu_send_message',
      label: t('Send Message'),
      visible: !!onSendMessage && !clinician?.isCaregiver,
      logEventProps: {
        page: EventPage.PatientMonitoring,
        subSource: eventSubSource,
        eventName: EVENTS.CLICK_SEND_MESSAGE,
      },
      onClick: () => onSendMessage(get(cell, 'row.original', {})),
    },
  ];
  return (
    <ActionDropdown
      testid="patient_monitoring_action_menu"
      label={label}
      dropdownOptions={menuList}
    />
  );
};

const SubmissionTable = ({
  data,
  onFetchData,
  onOpenDrawer,
  onShowSubmission,
  onShowPatientForm,
  onFetchNextPage,
  onDownloadSubmissions,
  onSendMessage,
  isFetched,
  isFetchingNextPage,
  hasNextPage,
  isSinglePatientView,
  initialSortBy,
  showDischargedLabel,
  showLoading,
  tableSettings,
  tableSettingsSinglePatient,
  clinic,
  renderTable,
  clinician,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  const history = useHistory();
  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(tableSettings, clinic, t),
    {
      id: 'actions',
      Header: '',
      Cell: ActionsCell,
      disableSortBy: true,
      width: '40px',
    },
  ];

  const singlePatientViewColumns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      tableSettingsSinglePatient,
      clinic,
      t
    ),
  ];

  if (
    (!singlePatientViewColumns && !tableSettings) ||
    (singlePatientViewColumns && !tableSettingsSinglePatient) ||
    showLoading
  ) {
    return <TableLoader />;
  }

  return (
    <Table
      clinician={clinician}
      renderTable={renderTable}
      columns={isSinglePatientView ? singlePatientViewColumns : columns}
      data={data}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={onFetchNextPage}
      onFetchData={onFetchData}
      onOpenDrawer={onOpenDrawer}
      onShowSubmission={onShowSubmission}
      onDownloadSubmissions={onDownloadSubmissions}
      onSendMessage={onSendMessage}
      onShowPatientForm={onShowPatientForm}
      onRowClick={onShowSubmission}
      showDischargedLabel={showDischargedLabel}
      t={t}
      isMobile={isMobile}
      isSinglePatientView={isSinglePatientView}
      history={history}
      initialSortBy={
        initialSortBy || {
          id: 'PATIENT_NAME',
          desc: true,
        }
      }
    />
  );
};

SubmissionTable.defaulProps = {
  onShowSubmission: () => {},
  onShowPatient: () => {},
};

export default SubmissionTable;
