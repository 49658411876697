const es = {
  'Only 3 months from the previously selected date is allowed':
    'Solo se permite 3 meses desde la fecha seleccionada anteriormente',
  of: 'de',
  Null: 'Nulo',
  Showing: 'Mostrando',
  'Clear All': 'Limpiar todo',
  Advanced: 'Avanzado',
  Gender: 'Género',
  'Messaging platform': 'Plataforma de Mensajería',
  'Last 60 days': 'Últimos 60 días',
  'Last 90 days': 'Últimos 90 días',
  'Error downloading file, please try again.':
    'Error al descargar el archivo, por favor inténtalo de nuevo.',
  'Download successful': 'Descarga exitosa',
  'Your download was successful': 'Tu descarga fue exitosa',
  'Please wait for the current upload to finish and try again':
    'Por favor espera a que la carga actual termine y vuelve a intentarlo',
  Programs: 'Programas',
  'Gender/DOB': 'Género/Fecha de Nacimiento',
  results: 'resultados',
  'Created by': 'Creado por',
  'Active patients': 'Pacientes Activos',
  Description: 'Descripción',
  'Enrolment Date': 'Fecha de Registro',
  'Removed Programs': 'Programas Eliminados',
  'Patient Name/ID': 'Nombre del Paciente/ID',
  'Enter group name': 'Ingresa el nombre del grupo',
  'Group description': 'Descripción del grupo',
  'Group name': 'Nombre del grupo',
  'Search by group name & description': 'Buscar por nombre y descripción',
  'Create patient groups to perform bulk actions (e.g. send broadcast message)':
    'Crea grupos de pacientes para realizar acciones masivas (por ejemplo, enviar mensajes de difusión)',
  'Send message to patient': 'Enviar mensaje al paciente',
  'Message Logs': 'Registros de Mensajes',
  'Add program': 'Añadir programa',
  'Created on': 'Creado en',
  'Add Program': 'Añadir Programa',
  'Select all groups': 'Seleccionar todos los grupos',
  'Patient Groups': 'Grupos de Pacientes',
  'Bulk actions': 'Acciones masivas',
  'Remove Programs': 'Eliminar Programas',
  'Create group': 'Crear grupo',
  'Enroll Into Program': 'Inscribirse en el programa',
  'Deactivate Patients': 'Desactivar Pacientes',
  'Select visible rows': 'Seleccionar filas visibles',
  'Select all patients': 'Seleccionar todos los pacientes',
  'Select all rows': 'Seleccionar todas las filas',
  'Custom selection': 'Selección personalizada',
  Actions: 'Acciones',
  'Select the program you want to remove them from':
    'Selecciona el programa del cual deseas removerlos',
  Email: 'Email',
  'Search patients by name or mobile':
    'Buscar pacientes por nombre y número de teléfono',
  'Your session is expired, log out and log back in again':
    'Su sesión ha caducado, cierre la sesión y vuelva a iniciarla.',
  'patients assigned to the user will need to be reassigned.':
    'pacientes asignados al usuario necesitarán ser reasignados',
  About: 'Acerca de',
  'Activate/pause reminders': 'Activar/Pausar recordatorios',
  Active: 'Activo',
  'Active forms': 'Formatos activos',
  'Active Patients': 'Pacientes Activos',
  'Add a comment': 'Añadir comentario',
  'Add another family member': 'Agregar otro miembro de la familia',
  'Add another user': 'Añadir usuario',
  'Add Family Member': 'Agregar Miembro de la Familia',
  'Add Family Member(s)': 'Agregar Miembro(s) de la Familia',
  'Add New Clinical User': 'Añadir Usuario Clínico',
  'Add New Family Member': 'Agregar Nuevo Miembro de la Familia',
  'Add new patient': 'Añadir paciente',
  'Add Patient': 'Añadir paciente',
  'Add new user': 'Añadir usuario',
  'Add clinical user': 'Añadir usuario clínico',
  'Add reminder': 'Agregar recordatorio',
  'Add users': 'Añadir usuarios',
  'Advanced reminder settings': 'Configuración avanzada de recordatorios',
  'After a fixed number of reminders have been sent':
    'Después de que un número fijo de recordatorios hayan sido enviados',
  'After patient discharge': 'Después de dar de baja al paciente',
  'After patient enrollment': 'Después de registrar al paciente',
  Alert: 'Alerta',
  'Alert Threshold': 'Umbral de alerta',
  'Alert thresholds': 'Umbrales de alerta',
  'Alerted via': 'Contactado vía',
  All: 'Todo',
  'All patient data': 'Datos de todos los pacientes',
  'All Patients': 'Todos los pacientes',
  'All submission data': 'Datos de todos los reportes',
  'All-time': 'Todo el tiempo',
  "Apply a filter for the Clinical User's name to view all patients they are assigned to.":
    'Aplicar un filtro para el nombre del Usuario Clínico para ver a todos sus pacientes asignados ',
  'Apply filters': 'Aplicar filtros',
  Apr: 'Abr',
  April: 'Abril',
  'Are you sure you want to leave Create/Edit Reminders page without saving your changes?':
    '¿Estás seguro que deseas salir de la página de Crear/Editar Recordatorios sin guardar cambios?',
  'At what time should your patient(s) receive reminders':
    '¿A qué hora deben recibir los recordatorios sus pacientes',
  Aug: 'Ago',
  August: 'Agosto',
  Back: 'Atrás',
  'Back to Clinical Settings': 'Volver a Ajustes Clínicos',
  Cancel: 'Cancelar',
  'Change language': 'Cambiar idioma',
  'Change profile picture': 'Cambiar foto de perfil',
  'Change workspace': 'Cambiar de espacio de trabajo',
  'Choose your dashboard language': 'Elige el idioma de tu plataforma',
  'Clinical Admin': 'Administrador',
  'Clinical Alerts': 'Alertas Clínicas',
  'Clinical Designation': 'Cargo clínico',
  'Clinical designation': 'Cargo clínico',
  'Clinical Settings': 'Ajustes Clínicos',
  'Clinical user': 'Usuario clínico',
  'Clinical user(s) in-charge': 'Usuario(s) clínico(s) a cargo',
  'Clinical user(s) to alert': 'Usuario(s) clínico(s) a alertar',
  'Clinical users': 'Usuarios clínicos',
  'Clinical Users': 'Usuarios clínicos',
  Close: 'Cerrar',
  Comments: 'Comentarios',
  Contact: 'Contacto',
  'Contact Us': 'Contáctanos',
  'Download user list': 'Descargar lista de usuarios',
  'Continue to receive alerts for all Clinical User(s)':
    'Continúe recibiendo alertas para todos los usuarios clínicos',
  'Create reminder': 'Crear un recordatorio',
  Current: 'Actual',
  'Current day': 'Este día',
  'Current month': 'Este mes',
  'Current reminder': 'Actual recordatorio',
  'Current week': 'Esta semana',
  'Custom Range': 'Rango Personalizado',
  CUSTOMIZE: 'PERSONALIZAR',
  'Customize for this individual': 'Personalizar para este individuo',
  'Customize one-time reminder': 'Personalizar recordatorio de una sola vez',
  'Customize reminder frequency': 'Personalizar frecuencia de recordatorios',
  'Data labels': 'Etiquetas de datos',
  Date: 'Fecha',
  'Date range': 'Rango de fechas',
  Day: 'Día',
  'day ago': 'día atrás',
  'Day(s)': 'Día(s)',
  'Day(s) after reminder': 'Día(s) después del recordatorio',
  'Day(s) after trigger event': 'Día(s) después del evento desencadenante',
  'Day(s) before reminder': 'Día(s) antes del recordatorio',
  'days ago': 'días atrás',
  'Deactivate {{profile name}}?': 'Desactivar {{profile name}}?',
  'Deactivate profile': 'Desactivar perfil',
  'Deactivate user': 'Desactivar usuario',
  Deactivated: 'Desactivado',
  Dec: 'Dic',
  December: 'Diciembre',
  Default: 'Predeterminado',
  'Define export': 'Especificaciones al exportar',
  'Export your patient data': 'Export your patient data',
  'Delete reminder': 'Eliminar recordatorio',
  Discard: 'Descartar',
  'Discharge {{profile name}}?': '¿Dar de baja a {{profile name}}?',
  'Discharge Patient': 'Dar de Baja al Paciente',
  'Discharge patient': 'Dar de Baja al Paciente',
  Discharged: 'Dados de Baja',
  'Discharged Date': 'Fecha de Egreso',
  'Discharged forms': 'Formatos dados de baja',
  'Download {{profile name}} submissions':
    'Descargar los reportes de {{profile name}}',
  'Download data': 'Descargar datos',
  'Download file': 'Descargar archivo',
  'Download submissions': 'Descargar reportes',
  Duration: 'Duración',
  "Duration before and after a reminder in which a patient's submission is considered to be compliant.":
    'Duración antes y después del recordatorio en el que el reporte del paciente se considerara en cumplimiento.',
  Edit: 'Editar',
  'Edit alert threshold': 'Editar umbral de alerta',
  'Edit Clinical User': 'Editar Usuario Clínico',
  'Edit clinical users': 'Editar Usuarios Clínicos',
  'Edit duration': 'Editar duración',
  'Edit Form Information': 'Editar Información del Formato',
  'Edit forms, clinical users, reminders, caregivers':
    'Editar formatos, usuarios clínicos, recordatorios, cuidadores',
  'Edit Patient Details': 'Editar Datos del Paciente',
  'Edit patient details': 'Editar Datos del Paciente',
  'Edit profile': 'Editar Perfil',
  'Edit time & frequency': 'Editar tiempo y frecuencia',
  'Enroll {{profile name}}?': '¿Registrar a {{profile name}}?',
  'Enroll Patient': 'Registrar Paciente',
  'Enrolled Date': 'Fecha de Registro',
  'Enrollment Date': 'Fecha de Registro',
  'Enter clinical designation': 'Ingresar cargo clínico',
  Equals: 'Es igual a',
  Export: 'Exportar',
  'Export as CSV': 'Exportar como CSV',
  'Export as PDF': 'Exportar como PDF',
  'Export data': 'Exportar datos',
  'Select file format': 'Select file format',
  'Export destination': 'Destino de exportación',
  'Download method': 'Download method',
  'Export graphs': 'Exportar gráficas',
  'Export patient list': 'Exportar lista de pacientes',
  'Export table': 'Exportar tabla',
  'Export user list': 'Exportar lista de usuarios',
  'Family Member': 'Miembro de la Familia',
  'Family Member Information': 'Información del Miembro de la Familia',
  'Family Member(s)': 'Miembro(s) de la Familia',
  'Family Members': 'Miembros de la Familia',
  Feb: 'Feb',
  February: 'Febrero',
  Filter: 'Filtrar',
  'Filter by': 'Filtrar por',
  Filters: 'Filtros',
  'First Name': 'Nombre(s)',
  'First name': 'Nombre(s)',
  "Follow-up reminders will be automatically sent to patients if they don't respond to the original reminder. They can be sent up to 8 hours after the initial reminder.":
    'Recordatorios de seguimiento serán enviados automáticamente a los pacientes si no respoden al recordatorio inicial. Pueden ser enviados hasta 8 horas después del recordatorio inicial.',
  'Form Information': 'Información del formato',
  Forms: 'Formatos',
  Frequency: 'Frecuencia',
  'GLOBAL DEFAULTS': 'PREDETERMINADOS GLOBALES',
  'Grand average': 'Promedio general',
  Graph: 'Gráfica',
  Graphs: 'Gráficas',
  'Greater than': 'Mayor que',
  'Greater than or equal': 'Mayor o igual que',
  'Help Guide': 'Ayuda',
  here: 'aquí',
  'Hide advanced reminder settings':
    'Ocultar configuración avanzada de recordatorios',
  History: 'Historial',
  hour: 'hora',
  'hour ago': 'hora atrás',
  'Hour(s) after reminder': 'Hora(s) después del recordatorio',
  'Hour(s) after trigger event': 'Hora(s) después del evento desencadenante',
  'Hour(s) before reminder': 'Hora(s) antes del recordatorio',
  'hours ago': 'horas atrás',
  'Immediately upon enrollment': 'Inmediatamente al registro',
  'Input duration': 'Introducir duración',
  Jan: 'Ene',
  January: 'Enero',
  Jul: 'Jul',
  July: 'Julio',
  Jun: 'Jun',
  June: 'Junio',
  'Last 1 day': 'Último 1 día',
  'Last 12 hours': 'Últimas 12 horas',
  'Last 14 Days': 'Últimos 14 días',
  'Last 2 hours': 'Últimas 2 horas',
  'Last 24 hours': 'Últimas 24 horas',
  'Last 30 days': 'Últimos 30 Días',
  'Last 4 hours': 'Últimas 4 horas',
  'Last 48 hours': 'Últimas 48 horas',
  'Last 7 Days': 'Últimos 7 Días',
  'Last 7 days': 'Últimos 7 Días',
  'Last 72 hours': 'Últimas 72 horas',
  'Last Name': 'Apellido(s)',
  'Last name': 'Apellido(s)',
  'Last Report': 'Último Reporte',
  'Last report': 'Último Reporte',
  'Last updated': 'Última actualización',
  'Latest Clinical Alerts': 'Últimas Alertas Clínicas',
  'Lesser than': 'Menor que',
  'Lesser than or equal to': 'Menor o igual que',
  'Load more': 'Descargar más',
  'Log a comment that is visible to patient':
    'Registrar un comentario que sea visible para el paciente',
  "Log a note that's only visible to clinical users":
    'El comentario solo será visible para usuarios clínicos',
  Logout: 'Cerrar Sesión',
  'View and manage your clinical users and their permissions':
    'Ver y administrar a tus usuarios clínicos y sus permisos',
  'Manage dashboard users and permissions.':
    'Administra a los usuarios de la plataforma y permisos.',
  Mar: 'Mar',
  March: 'Marzo',
  'Mark as resolved': 'Marcar como resuelto',
  May: 'Mayo',
  'Message via': 'Mensaje vía',
  'minute ago': 'minuto atrás',
  'Minute(s) after trigger event':
    'Minuto(s) después del evento desencadenante',
  'minutes ago': 'minutos atrás',
  'Monitoring Form': 'Formato de Monitoreo',
  'Monitoring form': 'Formato de Monitoreo',
  'Monitoring forms': 'Formatos de monitoreo',
  Month: 'Mes',
  'month ago': 'mes atrás',
  'Month(s)': 'Mese(s)',
  'Month(s) after reminder': 'Mes(es) después del recordatorio',
  'Month(s) after trigger event': 'Mes(es) después del evento desencadenante',
  'Month(s) before reminder': 'Mes(es) antes del recordatorio',
  'months ago': 'meses atrás',
  More: 'Más',
  'My Patients': 'Mis pacientes',
  'New reminder': 'Nuevo recordatorio',
  Next: 'Próximo',
  'Next day': 'Próximo día',
  'Next month': 'Próximo mes',
  'Next week': 'Próxima semana',
  'No options': 'Sin opciones',
  'No report': 'Sin reportes',
  'No reports': 'Sin reportes',
  'Select timeframe': 'Seleccionar rango de tiempo',
  'No Reports': 'Sin reportes',
  'No results found': 'No se encontraron resultados',
  'Non-compliant': 'Mal Apego',
  'Non-compliant Patients': 'Pacientes con Mal Apego',
  'Not applicable': 'No aplica',
  Nov: 'Nov',
  November: 'Noviembre',
  'Number of reminders sent': 'Número de recordatorios enviados',
  Oct: 'Oct',
  October: 'Octubre',
  'One-time': 'Una sola vez',
  'One-time reminders do not require a duration to be set.':
    'Para recordatorios de una sola vez no se requiere configurar duración',
  'Output format': 'Formato de salida',
  Overview: 'Página principal',
  Patient: 'Paciente',
  'Patient Details': 'Datos del paciente',
  'Patient Form': 'Formato del paciente',
  'Patient ID': 'Identificación del paciente',
  'Patient Information': 'Información del paciente',
  'Patient List': 'Lista de Pacientes',
  'Patient list': 'Lista de Pacientes',
  'Patient Monitoring': 'Seguimiento',
  'Patient name': 'Nombre del paciente',
  'Patient Name': 'Nombre del paciente',
  'Patient name A-Z': 'Nombre del paciente A-Z',
  'Patient name Z-A': 'Nombre del paciente Z-A',
  'Patients assigned': 'Pacientes asignados',
  Paused: 'En Pausa',
  'Phone No.': 'Número de teléfono',
  'Phone number': 'Número de teléfono',
  'Please ensure that the': 'Por favor asegurarse de que ',
  Previous: 'Anterior',
  'Previous day': 'Día anterior',
  'Previous month': 'Mes anterior',
  'Previous week': 'Semana anterior',
  Private: 'Privado',
  Public: 'Público',
  'Reactivate Profile': 'Reactivar perfil',
  'Reassign all patients to': 'Reasignar todos los pacientes a',
  'Reassign and deactivate user': 'Reasignar y desactivar usuario ',
  'Recipient email address': 'Dirección de correo electrónico del destinatario',
  Recurring: 'Recurrente',
  Reminder: 'recordatorio',
  'Reminder end': 'Recordatorio finaliza',
  'Reminder frequency': 'Frecuencia del recordatorio',
  'Reminder start': 'Iniciar recordatorio',
  'Reminder Type': 'Tipo de recordatorio',
  Reminders: 'Recordatorios',
  'reminders start & end dates do not overlap with each other.':
    'las fechas de inicio y término de los recordatorios no se sobrepongan entre ellas',
  'Reminders will end': 'Los recordatorios finalizarán',
  'Reminders will start': 'Los recordatorios iniciarán',
  'Remove Clinical User(s) from Alert':
    'Suspender Usuario(s) clínico(s) de alertas',
  'Remove Clinical User(s) to Alert':
    'Suspender Usuario(s) clínico(s) a alertar',
  Reported: 'Reportado',
  Required: 'Requerido',
  'Reset filters': 'Reiniciar filtros',
  Resolved: 'Resuelto',
  Role: 'Rol',
  'Account Type': 'Tipo de cuenta',
  Save: 'Guardar',
  'Save changes?': '¿Guardar cambios?',
  Saving: 'Guardando',
  'Schedule follow-up reminders': 'Programar recordatorios de seguimiento',
  'Search family member by name or mobile':
    'Buscar miembro de la familia por nombre o teléfono',
  'Search patients': 'Buscar pacientes',
  'Search Users': 'Buscar Usuarios',
  'seconds ago': 'segundos atrás',
  Select: 'Seleccionar',
  'Select a global default or create a custom reminder.':
    'Seleccionar predeterminado global o crear un recordatorio personalizado.',
  'Select data to export': 'Seleccionar datos a exportar',
  'Select Family Member(s)': 'Seleccionar Miembro(s) de la Familia',
  'Select message': 'Seleccionar Mensaje',
  'Select message template': 'Selecciona un mensaje predeterminado',
  'Select output format': 'Seleccionar formato de salida',
  'Select Patient(s)': 'Seleccionar Paciente(s)',
  'Select Reminder': 'Seleccionar Recordatorio',
  'Select reminder': 'Seleccionar Recordatorio',
  'Select role': 'Seleccionar rol',
  'Select service': 'Seleccionar servicio',
  'Send Message': 'Enviar mensaje',
  'Send message': 'Enviar mensaje',
  'Send now': 'Enviar ahora',
  'Send patients a reminder based on a trigger event like enrollment or discharge':
    'Enviar a los pacientes un recordatorio basado en un evento desencadenante como registro o al dar de baja',
  'Send patients reminders based on a repeating schedule':
    'Enviar recordatorios repetitivos a los pacientes de manera periódica',
  'Send reminder': 'Enviar recordatorio',
  'Send template message': 'Enviar mensaje predeterminado',
  'Send to email': 'Enviar al correo electrónico',
  Sep: 'Sep',
  September: 'Septiembre',
  'Set Date Range': 'Establecer Rango de Fechas',
  'Set reminders every': 'Establecer recordatorios cada',
  'Set up a new reminder for this patient':
    'Crear un nuevo recordatorio para este paciente',
  'Show data labels': 'Mostrar etiquetas de datos',
  'Sort patients by': 'Organizar pacientes por',
  Status: 'Estado',
  Submission: 'Reporte',
  'Submission compliance window': 'Ventana de cumplimiento de reportes',
  Submissions: 'Reportes',
  Submit: 'Enviar',
  'Submitted on (newest to oldest)':
    'Enviado en (del más reciente al más antiguo)',
  'Submitted on (oldest to newest)':
    'Enviado en (del más antiguo al más reciente)',
  'Switch workspace': 'Cambiar de espacio de trabajo',
  Table: 'Tabla',
  'The patient will no longer receive reminders':
    'El paciente ya no recibirá recordatorios',
  'The patient will receive a message informing them that they have been enrolled into the monitoring program(s)':
    'El paciente recibirá un mensaje informándole que ha sido inscrito en el programa(s) de monitoreo',
  'The patient will receive a message informing them that they have been removed from the monitoring program':
    'El paciente recibirá un mensaje informándole que ha sido removido del programa(s) de monitoreo',
  'The patient will start receiving reminders':
    'El paciente empezará a recibir recordatorios',
  "The patient's graphs and submissions will still be available for download under Patient List":
    'Las gráficas y reportes del paciente seguirán estando disponibles para descargar en la Lista de Pacientes',
  'The user will no longer be able to sign in to the Dashboard.':
    'El ususario ya no podrá acceder a la Plataforma.',
  "The user's comments will still be accessible in the Dashboard.":
    'Los comentarios del usuario seguirán disponibles en la Plataforma.',
  'Time reported': 'Fecha-hora del reporte',
  Today: 'Hoy',
  'Trigger event': 'Evento desencadenante',
  "Try adjusting your search or filter to find what you're looking for.":
    'Intenta ajustar tu búsqueda o filtros para encontrar lo que estás buscando.',
  'Type here': 'Escribe aquí',
  'Unread comments': 'Comentarios no leídos',
  'Unread Comments': 'Comentarios no leídos',
  'Unresolved Alerts': 'Alertas sin resolver',
  'Update avatar': 'Actualizar avatar',
  'Try adjusting your search or filter to find what you’re looking for.':
    'Intenta ajustar tu búsqueda o filtros para encontrar lo que estás buscando.',
  'Upon patient discharge': 'Al dar de baja al paciente',
  'User Information': 'Información del Usuario',
  'Patient has been removed from program':
    'Paciente ha sido removido del programa',
  'Choose the hour and minute from the dropdown menu':
    'Selecciona la hora y minuto del menú desplegable',
  'User list': 'Lista de usuario',
  'View all': 'Ver todo',
  'Create Group': 'Crear Grupo',
  'View all your patient submissions, alerts and non-compliant patients. Click a row to see more details.':
    'Ver todos los reportes de tus pacientes y sus tendencias clínicas. También puedes personalizar los ajustes clínicos del paciente. ',
  "View and manage all your patient's family members.":
    'Ver y administrar a todos los miembros de la familia de tu paciente.',
  'View and manage all patients and their individual settings':
    'Ver y administrar a todos tus pacientes, puedes personalizar umbrales de alerta y recordatorios de forma individual.',
  'View as graph': 'Ver como gráfica',
  'View as table': 'Ver como tabla',
  'View clinical settings': 'Ver ajustes clínicos',
  'View current reminders': 'Revisar los recordatorios actuales',
  'View graphs': 'Ver gráficas',
  'View profile': 'Ver perfil',
  'View submissions': 'Ver reportes',
  Week: 'Semana',
  'week ago': 'semana atrás',
  'Week(s)': 'Semana(s)',
  'Week(s) after reminder': 'Semana(s) después del recordatorio',
  'Week(s) before reminder': 'Semana(s) antes del recordatorio',
  'weeks ago': 'semanas atrás',
  'Weeks(s) after trigger event': 'Semana(s) después del evento desencadenante',
  Welcome: 'Bienvenido {{name}}',
  'Well done!': '¡Bien hecho!',
  'Download all your clinical user details as a CSV file.':
    'Descargar todos los detalles de tus usuarios clínicos en un archivo CSV.',
  'What happens when a patient is discharged?':
    '¿Qué pasa cuando un paciente se da de baja?',
  'What happens when a patient is enrolled?':
    '¿Qué pasa cuando se registra un paciente?',
  'What happens when an account is deactivated?':
    '¿Qué pasa cuando una cuenta se desactiva?',
  'Would you like to download all users in a CSV file?':
    '¿Te gustaría descargar todos los usuarios en un archivo CSV?',
  'You can individually reassign patients in':
    'Puedes reasignar individualmente a los pacientes en',
  'You have no unresolved alerts!': '¡No tienes alertas sin resolver!',
  'Your changes have not been saved': 'Tus cambios no han sido guardados',
  'Your changes have not been saved. Before proceeding, would you like to save or discard changes?':
    'Los cambios no han sido guardados. Antes de proceder, ¿te gustaría guardar o descartar los cambios?',

  '2h after meal': '2h después de comer',
  'Account type': 'Tipo de usuario',

  'Add new comment': 'Añadir comentario',

  Afternoon: 'Tarde',

  'Alert Thresholds': 'Umbrales de alerta',

  'Before Bedtime': 'Antes de acostarse',
  'Before meal': 'Antes de comer',
  'Blood glucose': 'Glicemia',
  'Blood pressure': 'Tensión arterial',

  'Clinical Alert Recipient 1': 'Alerta clínica 1',
  'Clinical Alert Recipient 2': 'Alerta clínica 2',

  'Clinical Parameter': 'Parámetro clínico',
  'Clinical User In-Charge': 'Usuario clínico responsable',
  'Clinical User Information': 'Información del usuario clínico',

  Customize: 'Ajustar',

  Diastolic: 'Diastólica',
  Discharge: 'Inactivar',

  'Edit reminder': 'Editar recordatorio',
  'Edit threshold': 'Editar umbral',
  Enroll: 'Ingresar',

  Evening: 'Tarde noche',

  Form: 'Formato',

  'Heart Rate': 'Frecuencia cardiaca',

  'If patient submits within this time window, they are considered compliant.':
    'Si el paciente reporta dentro de este rango se considera adherente',

  'Last 30 Days': 'Últimos 30 días',

  'Lesser than or equal': 'Menor o igual que',

  'Monitoring form(s)': 'Plantilla de seguimiento',

  Morning: 'Mañana',

  'Non-Compliant': 'No adherentes',

  Oxygen: 'Oxigeno',
  'Patient form': 'Formato del paciente',

  'Patient submissions': 'Reportes del paciente',
  'Phone Number': 'Número de teléfono',

  reminder: 'recordatorio',

  Replies: 'Comentarios',
  'Report Time': 'Fecha del reporte',

  'Select Reminder Frequency': 'Seleccionar frecuencia de recordatorios',
  'Send reminders every': 'Enviar recordatorios cada',
  'Settings will take effect upon save.':
    'Los ajustes se harán despues de guardar',

  'Submission window to be compliant': 'Rango del reporte para ser adherente',

  Systolic: 'Sistólica',

  Temperature: 'Temperatura',
  'Template message': 'Formato de mensaje',

  Thresholds: ' Umbrales',

  'What time should patients receive reminders?':
    '¿A qué hora deberían los pacientes recibir recordatorios?',
  'No Reports\\nThere were no reports in this date range':
    'No Reports\\nThere were no reports in this date range',
  'Submitted on (first to last)': 'Submitted on (first to last)',
  'Submitted on (last to first)': 'Submitted on (last to first)',
  Threshold: 'Threshold',
  Type: 'Type',
  'Alert (Resolved)': 'Alertas gestionadas',
  'Alert (Unresolved)': 'Alertas no gestionadas',

  Normal: 'Normal',
  Yesterday: 'Ayer',
  'Hospital name': 'Nombre de la institución',
  'Patient Submissions': 'Patient Submissions',
  Analytics: 'Informes',
  'Welcome back': 'Bienvenido',

  'No data available': 'Sin datos',
  '{{numOfDays}} days ago': 'dias del reporte',
  minutesAgo: 'minutos del reporte',
  hoursAgo: 'horas del reporte',

  'Edit clinical settings': 'Editar parámetros clínicos',
  'Add monitoring form': 'Añadir plantilla de seguimiento',

  Alerts: 'Alertas',

  'Download patient data': 'Descargar datos de paciente',
  Parameters: 'Parámetros clínicos',
  Details: 'Detalles',
  'Deactivate Profile': 'Desactivar Perfil',
  'Reset filter': 'Ajustar filtro',
  'Alerts received via': 'Alerta recibida via',
  Owner: 'Dueño',
  Editor: 'Dueño',
  Member: 'Miembro',
  Viewer: 'Solo lectura',
  'Bot MD': 'Bot MD',
  SMS: 'SMS',

  'Less than': 'Menor que',
  'or equal to': 'o igual que',
  'Loading Dashboard': 'Cargando del sitio web',

  "Apply a filter for the Clinical User's name to view all patients they are assigned to":
    'Aplicar un filtro para el nombre del usuario clínico para ver todos los pacientes a los que están asignados',

  'You are about to mark {{unreadCommentsCount}} unread comment{{plural}} as read. This action cannot be undone.':
    'You are about to mark **{{unreadCommentsCount}} unread comment{{plural}}** as read. This action cannot be undone.',
  'Mark all as resolved dialog message paragraph 1':
    'You are about to mark **{{markAsResolvedCount}} unresolved alert{{plural}}** as resolved. This action cannot be undone.',
  'Your name and the comment below will be automatically attached to all these alerts':
    'Your name and the comment below will be automatically attached to all these alerts.',
  Maintenance: `BotMD Care no estará disponible por mantenimiento programado desde {{startDateTime}} hasta aproximadamente {{endDateTime}}. El portal web no estará
  disponible, y las consultas de los pacientes se procesarán después del
  mantenimiento programado. Rogamos disculpen las molestias. 
  Póngase en contacto con <0>support</0> si tiene alguna pregunta.`,
};

export default es;
