const id = {
  'Only 3 months from the previously selected date is allowed':
    'Hanya 3 bulan dari tanggal yang dipilih sebelumnya yang diizinkan',
  of: 'dari',
  Null: 'Kosong',
  Showing: 'Menampilkan',
  'Clear All': 'Hapus Semua',
  Advanced: 'Lanjutan',
  Gender: 'Jenis Kelamin',
  'Messaging platform': 'Platform pesan',
  'Last 60 Days': '60 hari lalu',
  'Last 90 Days': '90 hari lalu',
  'Error downloading file, please try again.':
    'Gagal mengunduh berkas, silakan coba lagi.',
  'Download successful': 'Unduhan berhasil',
  'Your download was successful': 'Unduhan Anda berhasil',
  'Please wait for the current upload to finish and try again':
    'Harap tunggu hingga unggahan selesai dan coba lagi',
  Programs: 'Program',
  'Gender/DOB': 'Jenis Kelamin/Tanggal Lahir',
  results: 'Hasil',
  'Created by': 'Dibuat oleh',
  'Active patients': 'Pasien Aktif',
  Description: 'Deskripsi',
  'Enrolment Date': 'Tanggal Pendaftaran',
  'Removed Programs': 'Program yang dihapus',
  'Patient Name/ID': 'Nama Pasien/ID',
  'Enter group name': 'Masukkan nama grup',
  'Group description': 'Deskripsi grup',
  'Group name': 'Nama grup',
  'Search by group name & description':
    'Cari berdasarkan nama grup & deskripsi',
  'Create patient groups to perform bulk actions (e.g. send broadcast message)':
    'Buat grup pasien untuk melakukan tindakan massal (misalnya kirim pesan broadcast)',
  'Send message to patient': 'Kirim pesan ke pasien',
  'Message Logs': 'Riwayat Pesan',
  'Add program': 'Tambah program',
  'Created on': 'Dibuat pada',
  'Add Program': 'Tambah Program',
  'Select all groups': 'Pilih semua grup',
  'Patient Groups': 'Grup Pasien',
  'Remove Programs': 'Hapus program',
  'Create group': 'Buat grup',
  'Enroll Into Program': 'Daftar ke Program',
  'Deactivate Patients': 'Nonaktifkan Pasien',
  'Select visible rows': 'Pilih baris yang terlihat',
  'Select all patients': 'Pilih semua pasien',
  'Select all rows': 'Pilih semua baris',
  'Custom selection': 'Pilihan khusus',
  Actions: 'Tindakan',
  'Select the program you want to remove them from':
    'Pilih program yang ingin Anda hapus',
  Email: 'Email',
  'Search patients by name or mobile':
    'Cari pasien berdasarkan nama dan nomor telepon',
  'Your session is expired, log out and log back in again':
    'Su sesión ha caducado, cierre la sesión y vuelva a iniciarla.',
  'patients assigned to the user will need to be reassigned.':
    'pasien yang ditetapkan pengguna harus didaftarkan ulang.',
  'Activate/pause reminders': 'Aktifkan/matikan pengingat',
  'Active forms': 'Formulir aktif',
  'Active Patients': 'Pasien Aktif',
  'Add a comment': 'Tambah komentar',
  'Add another family member': 'Tambah anggota keluarga lainnya',
  'Add another user': 'Tambah pengguna lain',
  'Add Family Member': 'Tambah Keluarga',
  'Add Family Member(s)': 'Tambah Keluarga',
  'Add New Clinical User': 'Tambah pengguna klinis',
  'Add New Family Member': 'Tambah Keluarga',
  'Add new patient': 'Tambah pasien',
  'Add patient': 'Tambah pasien',
  'Add new user': 'Tambah pengguna',
  'Add clinical user': 'Tambah pengguna klinis',
  'Add reminder': 'Tambahkan pengingat',
  'Add users': 'Tambah pengguna',
  'Advanced reminder settings': 'Pengaturan pengingat lanjutan',
  'After a fixed number of reminders have been sent':
    'Setelah beberapa jumlah pengingat telah dikirim',
  'After patient discharge': 'Setelah pasien dipulangkan',
  'After patient enrollment': 'Setelah pendaftaran pasien',
  Alert: 'Peringatan',
  'Alert Threshold': 'Ambang Peringatan',
  'Alert thresholds': 'Ambang Peringatan',
  'Alerted via': 'Peringatan dari',
  All: 'Semua',
  'All patient data': 'Semua data pasien',
  'All Patients': 'Semua Pasien',
  'All submission data': 'Semua laporan pasien',
  'All-time': 'Sepanjang waktu',
  "Apply a filter for the Clinical User's name to view all patients they are assigned to.":
    'Terapkan filter untuk melihat seluruh pasien yang ditugaskan kepada nama pengguna klinis berikut',
  'Apply filters': 'Terapkan filter',
  Apr: 'Apr',
  April: 'April',
  'Are you sure you want to leave Create/Edit Reminders page without saving your changes?':
    'Apakah Anda yakin untuk menutup halaman Buat/Edit Pengingat tanpa menyimpan perubahan?',
  'At what time should your patient(s) receive reminders':
    'Kapan pasien Anda harus menerima pengingat',
  Aug: 'Agu',
  August: 'Agustus',
  Back: 'Kembali',
  'Back to Clinical Settings': 'Kembali ke Pengaturan Klinis',
  Cancel: 'Batalkan',
  'Change language': 'Ganti bahasa',
  'Change profile picture': 'Ganti foto profil',
  'Change workspace': 'Ubah ruang kerja',
  'Choose your dashboard language': 'Pilih Bahasa untuk Dasbor Anda',
  'Clinical Admin': 'Admin Klinis',
  'Clinical Alerts': 'Peringatan Klinis',
  'Clinical Designation': 'Jabatan',
  'Clinical Settings': 'Pengaturan Klinis',
  'Clinical user': 'Pengguna Klinis',
  'Clinical user(s) in-charge': 'Pengguna klinis penanggung jawab',
  'Clinical user(s) to alert': 'Pengguna klinis penerima peringatan',
  'Clinical users': 'Pengguna Klinis',
  'Clinical Users': 'Pengguna Klinis',
  Close: 'Tutup',
  Comments: 'Komentar',
  Contact: 'Nomor HP',
  'Contact Us': 'Hubungi Kami',
  'Continue to receive alerts for all Clinical User(s)':
    'Pengguna klinis akan tetap menerima peringatan',
  'Create reminder': 'Buat pengingat',
  Current: 'Saat ini',
  'Current day': 'Hari ini',
  'Current month': 'Bulan ini',
  'Current reminder': 'Pengingat yang digunakan sekarang',
  'Current week': 'Minggu ini',
  'Custom Range': 'Pilih rentang waktu lainnya',
  CUSTOMIZE: 'Sesuaikan',
  'Customize for this individual': 'Sesuaikan untuk individu ini',
  'Customize one-time reminder': 'Buat pengingat 1x',
  'Customize reminder frequency': 'Sesuaikan frekuensi pengingat',
  'Data labels': 'Label data',
  Date: 'Tanggal',
  'Date range': 'Rentang data',
  Day: 'Hari',
  'day ago': 'hari lalu',
  'Day(s)': 'Hari',
  'Day(s) after reminder': 'Hari setelah pengingat',
  'Day(s) after trigger event': 'Hari setelah kondisi yang dipilih',
  'Day(s) before reminder': 'Hari sebelum pengingat',
  'days ago': 'hari lalu',
  'Deactivate {{profile name}}?': 'Nonaktifkan {{profile name}}?',
  'Deactivate profile': 'Nonaktifkan profil',
  'Deactivate user': 'Nonaktifkan pengguna',
  Deactivated: 'Nonaktif',
  Dec: 'Des',
  December: 'Desember',
  Default: 'Standar',
  'Define export': 'Tentukan ekspor',
  'Export your patient data': 'Export your patient data',
  'Delete reminder': 'Hapus pengingat',
  Discard: 'Buang',
  'Discharge {{profile name}}?': 'Pulangkan {{profile name}}?',
  'Discharge Patient': 'Pulangkan pasien',
  'Discharge patient': 'Pulangkan pasien',
  Discharged: 'Dipulangkan',
  'Discharged Date': 'Tanggal dipulangkan',
  'Discharged forms': 'Formulir Pasien yang Dipulangkan',
  'Download {{profile name}} submissions': 'Unduh laporan {{profile name}}',
  'Download data': 'Unduh data',
  'Download file': 'Unduh berkas',
  'Download submissions': 'Unduh laporan',
  Duration: 'Durasi',
  "Duration before and after a reminder in which a patient's submission is considered to be compliant.":
    'Durasi laporan pasien diterima pada sebelum dan sesudah pengingat dikirim sehingga pasien dianggap pasien yang patuh.',
  Edit: 'Ubah',
  'Edit alert threshold': 'Edit ambang batas peringatan',
  'Edit Clinical User': 'Ubah Pengguna Klinis',
  'Edit clinical users': 'Edit Pengguna Klinis',
  'Edit duration': 'Edit durasi',
  'Edit Form Information': 'Ubah Informasi Formulir',
  'Edit forms, clinical users, reminders, caregivers':
    'Ubah formulir, pengguna klinis, pengingat, dan caregiver',
  'Edit Patient Details': 'Ubah rincian pasien',
  'Edit patient details': 'Ubah rincian pasien',
  'Edit profile': 'Ubah profil',
  'Edit time & frequency': 'Edit waktu & frekuensi',
  'Enroll {{profile name}}?': 'Daftarkan {{profile name}}?',
  'Enroll Patient': 'Daftarkan pasien',
  'Enrolled Date': 'Tanggal Pendaftaran',
  'Enrollment Date': 'Tanggal Pendaftaran',
  'Enter clinical designation': 'Masukkan jabatan',
  Equals: 'Sama dengan',
  Export: 'Ekspor',
  'Export as CSV': 'Ekspor sebagai CSV',
  'Export as PDF': 'Ekspor sebagai PDF',
  'Export data': 'Ekspor data',
  'Select file format': 'Select file format',
  'Export destination': 'Tujuan unduhan',
  'Download method': 'Download method',
  'Export graphs': 'Ekspor grafik',
  'Export patient list': 'Ekspor daftar pasien',
  'Export table': 'Ekspor tabel',
  'Export user list': 'Ekspor daftar pengguna',
  'Download user list': 'Unduh daftar pengguna',
  'Family Member': 'Anggota Keluarga',
  'Family Member Information': 'Informasi Anggota Keluarga',
  'Family Member(s)': 'Anggota Keluarga',
  'Family Members': 'Anggota Keluarga',
  Feb: 'Feb',
  February: 'Februari',
  Filter: 'Filter',
  'Filter by': 'Filter berdasarkan',
  Filters: 'Filter',
  'First Name': 'Nama Depan',
  'First name': 'Nama Depan',
  "Follow-up reminders will be automatically sent to patients if they don't respond to the original reminder. They can be sent up to 8 hours after the initial reminder.":
    'Pengingat lanjutan akan dikirim kepada pasien secara otomatis apabila pasien tidak merespon pengingat utamanya. Pengingat lanjutan dapat dikirim sampai 8 jam setelah pengingat utama.',
  'Form Information': 'Informasi Formulir',
  Forms: 'Formulir',
  Frequency: 'Frekuensi',
  'GLOBAL DEFAULTS': 'Default ',
  'Grand average': 'Rata-rata keseluruhan',
  Graph: 'Grafik',
  Graphs: 'Grafik',
  'Greater than': 'Lebih besar dari',
  'Greater than or equal': 'Lebih besar sama dengan',
  'Help Guide': 'Pusat Bantuan',
  here: 'di sini',
  'Hide advanced reminder settings':
    'Sembunyikan pengaturan pengingat lanjutan',
  History: 'Riwayat',
  hour: 'jam',
  'hour ago': 'jam lalu',
  'Hour(s) after reminder': 'Jam setelah pengingat',
  'Hour(s) after trigger event': 'Jam setelah kondisi yang dipilih',
  'Hour(s) before reminder': 'Jam sebelum pengingat',
  'hours ago': 'jam lalu',
  'Immediately upon enrollment': 'Segera setelah pendaftaran',
  'Input duration': 'Masukkan durasi',
  Jan: 'Jan',
  January: 'Januari',
  Jul: 'Jul',
  July: 'Juli',
  Jun: 'Jun',
  June: 'Juni',
  'Last 1 day': '1 hari lalu',
  'Last 12 hours': '12 jam terakhir',
  'Last 14 Days': '14 Hari Terakhir',
  'Last 2 hours': '2 jam terakhir',
  'Last 24 hours': '24 jam terakhir',
  'Last 30 days': '30 hari lalu',
  'Last 4 hours': '4 jam terakhir',
  'Last 48 hours': '48 jam terakhir',
  'Last 7 Days': '7 Hari Terakhir',
  'Last 7 days': '7 Hari Terakhir',
  'Last 72 hours': '72 jam terakhir',
  'Last Name': 'Nama Belakang',
  'Last name': 'Nama Belakang',
  'Last Report': 'Terakhir lapor',
  'Last report': 'Terakhir lapor',
  'Last updated': 'Terakhir diperbaharui pada',
  'Latest Clinical Alerts': 'Peringatan Klinis Terbaru',
  'Lesser than': 'Kurang dari',
  'Lesser than or equal to': 'Kurang dari sama dengan',
  'Load more': 'Muat lebih banyak',
  'Log a comment that is visible to patient':
    'Masukkan komentar yang dapat dilihat pasien',
  "Log a note that's only visible to clinical users":
    'Masukkan catatan yang hanya dapat dilihat oleh pengguna klinis',
  Logout: 'Keluar',
  'View and manage your clinical users and their permissions':
    'Lihat dan kelola pengguna klinis dan perizinan mereka',
  'Manage dashboard users and permissions.':
    'Kelola pengguna dasbor dan perizinannya.',
  Mar: 'Mar',
  March: 'Maret',
  'Mark as resolved': 'Tandai sudah selesai',
  May: 'Mei',
  'Message via': 'Kirim pesan via',
  'minute ago': 'menit lalu',
  'Minute(s) after trigger event': 'Menit setelah kondisi yang dipilih',
  'minutes ago': 'menit lalu',
  'Monitoring Form': 'Formulir pemantauan',
  'Monitoring form': 'Formulir pemantauan',
  'Monitoring forms': 'Formulir pemantauan',
  Month: 'Bulan',
  'month ago': 'bulan lalu',
  'Month(s)': 'Bulan',
  'Month(s) after reminder': 'Bulan setelah pengingat',
  'Month(s) after trigger event': 'Bulan setelah kondisi yang dipilih',
  'Month(s) before reminder': 'Bulan sebelum pengingat',
  'months ago': 'bulan lalu',
  More: 'Lihat lebih banyak',
  'My Patients': 'Pasien saya',
  'New reminder': 'Pengingat baru',
  Next: 'Selanjutnya',
  'Next day': 'Besok',
  'Next month': 'Bulan depan',
  'Next week': 'Minggu depan',
  'No options': 'Tidak ada pilihan',
  'No report': 'Tidak ada laporan',
  'No reports': 'Tidak ada laporan',
  'Select timeframe': 'Pilih rentang waktu',
  'No Reports': 'Tidak ada laporan',
  'No results found': 'Tidak ada hasil yang ditemukan',
  'Non-compliant': 'Tidak Patuh',
  'Non-compliant Patients': 'Pasien yang Tidak Patuh',
  'Not applicable': 'Tidak dapat diterapkan',
  Nov: 'Nov',
  November: 'November',
  'Number of reminders sent': 'Jumlah pengingat yang dikirim',
  Oct: 'Okt',
  October: 'Oktober',
  'One-time': 'Satu kali',
  'One-time reminders do not require a duration to be set.':
    'Pengingat 1x tidak perlu diatur durasinya',
  'Output format': 'Format output',
  Overview: 'Gambaran',
  Patient: 'Pasien',
  'Patient Details': 'Rincian Pasien',
  'Patient Form': 'Formulir Pasien',
  'Patient ID': 'ID Pasien',
  'Patient Information': 'Informasi Pasien',
  'Patient List': 'Daftar Pasien',
  'Patient list': 'Daftar Pasien',
  'Patient Monitoring': 'Pemantauan Pasien',
  'Patient name': 'Nama Pasien',
  'Patient Name': 'Nama Pasien',
  'Patient name A-Z': 'Nama pasien A-Z',
  'Patient name Z-A': 'Nama pasien Z-A',
  'Patients assigned': 'Pasien yang ditugaskan',
  Paused: 'Dihentikan',
  'Phone No.': 'Nomor Ponsel',
  'Phone number': 'Nomor Ponsel',
  'Please ensure that the': 'Harap pastikan bahwa',
  Previous: 'Sebelumnya',
  'Previous day': 'Kemarin',
  'Previous month': 'Bulan lalu',
  'Previous week': 'Minggu lalu',
  Private: 'Privat',
  Public: 'Publik',
  'Reactivate Profile': 'Aktifkan kembali profil',
  'Reassign all patients to': 'Daftarkan ulang semua pasien ke',
  'Reassign and deactivate user': 'Daftarkan dan nonaktifkan pengguna',
  'Recipient email address': 'Alamat email penerima',
  Recurring: 'Sedang berlangsung',
  Reminder: 'Pengingat',
  'Reminder end': 'Pengingat selesai',
  'Reminder frequency': 'Frekuensi pengingat',
  'Reminder start': 'Pengingat mulai',
  'Reminder Type': 'Jenis pengingat',
  Reminders: 'Pengingat',
  'reminders start & end dates do not overlap with each other.':
    'Tanggal mulai & selesai pengingat tidak bersamaan.',
  'Reminders will end': 'Pengingat akan selesai',
  'Reminders will start': 'Pengingat akan mulai',
  'Remove Clinical User(s) from Alert':
    'Pengguna klinis tidak akan menerima peringatan lagi',
  'Remove Clinical User(s) to Alert':
    'Hapus pengguna klinis sebagai penerima peringatan',
  Reported: 'Terakhir Lapor',
  Required: 'Wajib diisi',
  'Reset filters': 'Atur ulang filter',
  Resolved: 'Terselesaikan',
  Role: 'Peran Akses ',
  'Account Type': 'Jenis Akun',
  Save: 'Simpan',
  'Save changes?': 'Simpan perubahan?',
  Saving: 'Menyimpan',
  'Schedule follow-up reminders': 'Jadwalkan pengingat kunjungan tindak lanjut',
  'Search family member by name or mobile':
    'Cari anggota keluarga berdasarkan nama atau nomor telepon',
  'Search patients': 'Cari pasien',
  'Search Users': 'Cari Pengguna',
  'seconds ago': 'detik lalu',
  Select: 'Pilih',
  'Select a global default or create a custom reminder.':
    'Pilih pengingat umum atau buat pengingat sesuai kebutuhan.',
  'Select data to export': 'Pilih data untuk diekspor',
  'Select Family Member(s)': 'Pilih Anggota Keluarga',
  'Select message': 'Pilih pesan',
  'Select message template': 'Pilih pesan templat',
  'Select output format': 'Pilih format output',
  'Select Patient(s)': 'Pilih Pasien',
  'Select Reminder': 'Pilih pengingat',
  'Select reminder': 'Pilih pengingat',
  'Select role': 'Pilih peran ',
  'Select service': 'Pilih layanan',
  'Send Message': 'Kirim pesan',
  'Send message': 'Kirim pesan',
  'Send now': 'Kirim sekarang',
  'Send patients a reminder based on a trigger event like enrollment or discharge':
    'Kirim pengingat ke pasien berdasarkan peristiwa pemicu seperti pendaftaran atau pemulangan pasien',
  'Send patients reminders based on a repeating schedule':
    'Kirim pengingat ke pasien berdasarkan jadwal yang telah ditentukan',
  'Send reminder': 'Kirim pengingat',
  'Send template message': 'Kirim pesan template',
  'Send to email': 'Kirim ke email',
  Sep: 'Sep',
  September: 'September',
  'Set Date Range': 'Tetapkan Rentang Tanggal',
  'Set reminders every': 'Buat pengingat setiap',
  'Set up a new reminder for this patient':
    'Buat pengingat baru untuk pasien ini',
  'Show data labels': 'Tampilkan label data',
  'Sort patients by': 'Urutkan pasien berdasarkan',
  Status: 'Status',
  Submission: 'Laporan',
  'Submission compliance window': 'Jendela kepatuhan pengiriman laporan',
  Submissions: 'Laporan',
  Submit: 'Kirim',
  'Submitted on (newest to oldest)': 'Waktu lapor (terakhir ke pertama)',
  'Submitted on (oldest to newest)': 'Waktu lapor (pertama ke terakhir)',
  'Switch workspace': 'Alihkan ruang kerja',
  Table: 'Tabel',
  'The patient will no longer receive reminders':
    'Pasien tidak akan menerima pengingat lagi.',
  'The patient will receive a message informing them that they have been enrolled into the monitoring program(s)':
    'Pasien akan menerima pesan yang memberitahukan bahwa mereka telah terdaftar dalam program pemantauan.',
  'The patient will receive a message informing them that they have been removed from the monitoring program':
    'Pasien akan menerima pesan yang memberitahukan bahwa mereka telah dipulangkan dari program pemantauan',
  'The patient will start receiving reminders':
    'Pasien akan mulai menerima pengingat.',
  "The patient's graphs and submissions will still be available for download under Patient List":
    'Grafik dan laporan pasien akan tetap tersedia untuk diunduh di bawah Daftar Pasien.',
  'The user will no longer be able to sign in to the Dashboard.':
    'Pengguna tidak dapat masuk ke Dasbor lagi.',
  "The user's comments will still be accessible in the Dashboard.":
    'Komentar pengguna akan tetap dapat diakses di Dasbor.',
  'Time reported': 'Waktu laporan',
  Today: 'Hari Ini',
  'Trigger event': 'Kondisi yang dipilih',
  "Try adjusting your search or filter to find what you're looking for.":
    'Silahkan sesuaikan penelusuran atau filter Anda untuk menemukan apa yang Anda cari.',
  'Type here': 'Ketik di sini',
  'Unread comments': 'Komentar yang belum dibaca',
  'Unread Comments': 'Komentar yang belum dibaca',
  'Unresolved Alerts': 'Peringatan Belum Terselesaikan',
  'Update avatar': 'Perbaharui avatar',
  'Upon patient discharge': 'Pada saat pasien dipulangkan',
  'Try adjusting your search or filter to find what you’re looking for.':
    'Mencoba menyesuaikan pencarian atau filter Anda untuk menemukan apa yang Anda cari.',
  'User Information': 'Informasi Pengguna',
  'Patient has been removed from program':
    'Pasien telah dipulangkan dari program',
  'User list': 'Daftar pengguna',
  'View all': 'Tampilkan semua',
  'Choose the hour and minute from the dropdown menu':
    'Pilih jam dan menit dari menu dropdown',
  'Create Group': 'Buat Grup',
  'View all your patient submissions, alerts and non-compliant patients. Click a row to see more details.':
    'Tampilkan semua laporan, tren klinis pasien, dan sesuaikan pengaturan untuk setiap pasien.',
  "View and manage all your patient's family members.":
    'Lihat dan kelola semua anggota keluarga pasien.',
  'View and manage all patients and their individual settings':
    'Tampilkan dan kelola semua pasien Anda termasuk batasan untuk peringatan klinis dan pengingat.',
  'View as graph': 'Tampilkan sebagai grafik',
  'View as table': 'Tampilkan sebagai tabel',
  'View clinical settings': 'Tampilkan Pengaturan Klinis',
  'View current reminders': 'Lihat pengingat yang aktif saat ini',
  'View graphs': 'Tampilkan grafik',
  'View profile': 'Tampilkan profil',
  'View submissions': 'Tampilkan laporan',
  Week: 'Minggu',
  'week ago': 'minggu lalu',
  'Week(s)': 'Minggu',
  'Week(s) after reminder': 'Minggu setelah pengingat',
  'Week(s) before reminder': 'Minggu sebelum pengingat',
  'weeks ago': 'minggu lalu',
  'Weeks(s) after trigger event': 'Minggu setelah kondisi yang dipilih',
  Welcome: 'Selamat datang {{name}}',
  'Well done!': 'Bagus!',
  'Download all your clinical user details as a CSV file.':
    'Unduh semua data pengguna klinis Anda dalam berkas CSV.',
  'What happens when a patient is discharged?':
    'Apa yang terjadi saat pasien dipulangkan?',
  'What happens when a patient is enrolled?':
    'Apa yang terjadi saat pasien didaftarkan?',
  'What happens when an account is deactivated?':
    'Apa yang terjadi ketika akun dinonaktifkan?',
  'Would you like to download all users in a CSV file?':
    'Apakah Anda ingin mengunduh data semua pengguna dalam berkas CSV?',
  'You can individually reassign patients in':
    'Anda dapat mendaftarkan pasien secara mandiri pada',
  'You have no unresolved alerts!':
    'Anda tidak memiliki peringatan yang belum terselesaikan!',
  'Your changes have not been saved': 'Perubahan Anda belum disimpan',
  'Your changes have not been saved. Before proceeding, would you like to save or discard changes?':
    'Perubahan Anda belum disimpan. Sebelum melanjutkan, apakah Anda ingin menyimpan atau membuang perubahan?',

  '2h after meal': '2 jam setelah makan',
  'Account type': 'Jenis Akun',
  Active: 'Aktif',
  'Alert Thresholds': 'Ambang Peringatan',
  'Before Bedtime': 'Sebelum tidur',
  'Before meal': 'Sebelum makan',
  'Blood glucose': 'Gula darah',
  'Blood pressure': 'Tekanan darah',
  'Clinical Alert Recipient 1': 'Penerima Peringatan Klinis 1',
  'Clinical Alert Recipient 2': 'Penerima Peringatan Klinis 2',

  'Clinical Parameter': 'Parameter Klinis',

  'Clinical User In-Charge': 'Pengguna Klinis Penanggung Jawab',
  'Clinical User Information': 'Informasi Pengguna Klinis',
  Customize: 'Sesuaikan',
  Details: 'Rincian',
  Discharge: 'Pulangkan',

  'Edit reminder': 'Ubah pengingat',
  'Edit threshold': 'Ubah ambang batas',
  Enroll: 'Daftar',

  Form: 'Formulir',

  'Heart Rate': 'Detak jantung',

  'If patient submits within this time window, they are considered compliant.':
    'Jika pasien mengirimkan laporan sesuai waktu berikut, maka pasien dianggap patuh.',

  'Last 30 Days': '30 hari lalu',

  'Lesser than or equal': 'Kurang dari sama dengan',

  'Monitoring form(s)': 'Formulir pemantauan',

  'Non-Compliant': 'Tidak Patuh',

  Oxygen: 'Oksigen',

  'Patient submissions': 'Laporan Pasien',
  'Phone Number': 'Nomor Ponsel',

  reminder: 'Pengingat',
  Replies: 'Balasan',
  'Report Time': 'Waktu Lapor',

  'Select Reminder Frequency': 'Pilih Frekuensi Pengingat',

  'Send reminders every': 'Kirim pesan setiap',
  'Settings will take effect upon save.':
    'Pengaturan akan berlaku setelah disimpan',

  'Submission window to be compliant':
    'Pasien dianggap patuh apabila mengumpulkan laporan pada',

  Systolic: 'Sistolik',
  Temperature: 'Suhu',
  'Template message': 'Pesan template',

  Thresholds: 'Ambang',

  'What time should patients receive reminders?':
    'Jam berapa pasien harus menerima pengingat?',

  About: 'Tentang',
  Afternoon: 'Siang',

  Diastolic: 'Diastolik',
  'Edit clinical settings': 'Sunting pengaturan klinis',
  'Edit User': 'Ubah',
  Evening: 'Malam',
  'Glucose Reading': 'Laporan Gula Darah',

  Morning: 'Pagi',
  'No Reports\\nThere were no reports in this date range':
    'Tidak Ada Laporan\\nTidak ada laporan dalam rentang tanggal ini.',
  'Out of range': 'Di luar ambang batas',

  'Submitted on (first to last)': 'Waktu lapor (pertama ke terakhir)',
  'Submitted on (last to first)': 'Waktu lapor (terakhir ke pertama)',
  Threshold: 'Ambang Batas',
  Type: 'Jenis',
  'Alert (Resolved)': 'Peringatan (Selesai)',
  'Hospital name': 'Nama Rumah Sakit',
  'Patient Submissions': 'Laporan Pasien',
  'Help Center': 'Pusat Bantuan',
  'Non Compliant Patients': 'Pasien yang Tidak Patuh',
  'No data available': 'Tidak ada data yang tersedia',
  '22 days ago': '22 hari yang lalu',
  Normal: 'Normal',
  Klinisi: 'Klinisi',
  'View enrollment form': 'Lihat formulir pendaftaran',
  'Download patient data': 'Unduh data pasien',
  'View and manage all your patients including individual alert tresholds and reminders.':
    'Lihat dan kelola semua pasien Anda termasuk batasan untuk peringatan klinis dan pengingat.',
  'Heart Beat': 'Detak Jantung',
  'Oxygen Percentage': 'Saturasi Oksigen',
  'Body Temperature': 'Suhu Tubuh',
  'Show profile': 'Lihat profil',
  'Add monitoring form': 'Tambah formulir pemantauan',
  'Alerted Via': 'Peringatan Melalui',
  'Deactive Profil': 'Nonaktifkan Profil',
  'Reset filter': 'Atur ulang filter',
  'Alerts received via': 'Terima peringatan melalui',
  'Associate Consultant': 'Dokter Pre Konsulen',
  'Care Coordinator': 'Koordinator Pelayanan',
  Consultant: 'Dokter Konsulen',
  Nurse: 'Perawat',
  Pharmacist: 'Apoteker',
  'Senior Consultant': 'Dokter Konsulen Senior',
  'Senior Nurse': 'Perawat Senior',
  Owner: 'Pemilik',
  Editor: 'Penyunting',
  Member: 'Anggota',
  Viewer: 'Akses Melihat',
  'Bot MD': 'Bot MD',
  SMS: 'SMS',
  Daily: 'Setiap hari',
  'Belum Terselesaikan Peringatan': 'Peringatan Belum Terselesaikan',
  Alerts: 'Peringatan',
  'Alert (Unresolved)': 'Peringatan (Belum Terselesaikan)',
  'Submitted On': 'Submitted On',
  'Data range': 'Rentang data',
  Yesterday: 'Kemarin',
  Parameters: 'Parameter',
  '04Aug2021008': '4 Agustus 2021 00:08',
  hb50: 'HB: 50',
  '13Aug1208': '13 Agustus, 12:08',
  Analytics: 'Analisis',
  '{{numOfDays}} days ago': '{{numOfDays}} hari yang lalu',
  'Welcome back': 'Selamat datang kembali',

  'Less than': 'Kurang dari',
  'or equal to': 'atau sama dengan',

  'Loading Dashboard ': 'Loading Dashboard',

  "Apply a filter for the Clinical User's name to view all patients they are assigned to":
    'Terapkan filter untuk nama Pengguna Klinis untuk melihat semua pasien yang ditugaskan',

  'You are about to mark {{unreadCommentsCount}} unread comment{{plural}} as read. This action cannot be undone.':
    'You are about to mark **{{unreadCommentsCount}} unread comment{{plural}}** as read. This action cannot be undone.',
  'Mark all as resolved dialog message paragraph 1':
    'You are about to mark **{{markAsResolvedCount}} unresolved alert{{plural}}** as resolved. This action cannot be undone.',
  'Your name and the comment below will be automatically attached to all these alerts':
    'Your name and the comment below will be automatically attached to all these alerts.',
  Maintenance: `BotMD Care tidak akan tersedia untuk pemeliharaan terjadwal dari {{startDateTime}} hingga sekitar {{endDateTime}}. Portal web tidak akan
    tersedia, dan pertanyaan pasien akan diproses setelah
    pemeliharaan terjadwal. Kami mohon maaf atas ketidaknyamanan ini.
    Silakan hubungi <0>support</0> jika ada pertanyaan`,
};
export default id;
